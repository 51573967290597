//@import "@sx-core/src/scss/element/accordion.scss";

.accordion__item:not(:first-child) {
    margin-top: pxToRem(8);
}

.accordion-item {


    &__header {

        @include getFigmaStyle('Desktop/LG/Regular/Default');
        position: relative;

        &:after {
            content: '+';
            padding-top: 5px;
            padding-left: 1px;
            //background: red;
            position: absolute;
            top: calc(50% - 18px);
            display: flex;
            align-items: center;
            justify-content: center;

            font-weight: 300;
            right: pxToRem(16);
            width: pxToRem(36);
            height: pxToRem(37);
            //background-image: inline-svg('arrow-down-small-thick',   $color-black);
            //background-position: center;
            //background-size: 12px;
            //background-repeat: no-repeat;
            border-radius: 50%;
            transition: all .3s ease;
        }
    }

    &__body {
        padding: pxToRem(36) pxToRem(16);
        h3 {
            font-family: Poppins;
        }
    }
}
.uk-open .accordion-item__header:after {
    transform: rotate(-45deg);
}