
.teaser--small-outer {
    margin-top: pxToRem(24);
    margin-bottom: pxToRem(24);

}
.teaser--small {
    .teaser-item__container {
        margin-bottom: $spacing-l;
    }
}

.teaser-small__grid {
    row-gap: pxToRem(48);
}

.teaser__item--small, .teaser-item-small {

    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: $color-white;
    transition: all .3s ease;
    border-radius: 16px;
    overflow: hidden;
    margin: 0;

    &:hover {
        box-shadow: 0 4px 36px rgba($color-black, .3);
    }
}

.teaser__item--small, .teaser-item-small {
    .splide--inner .splide__arrow {
        right: $spacing-xs;
        width: 32px;
        height: 32px;
        background-image: inline-svg('arrow-m', $color-white, transparent);
        background-size: 28px 28px;

        &.splide__arrow--prev {
            left: $spacing-xs;
            transform: rotate(0) translateY(-50%);

            background-image: inline-svg('arrow-m-left', $color-white, transparent);
        }
    }

    .teaser-item-small__body{
        padding: pxToRem(24);
        color: $font-color;
        //
        display: flex;
        flex-direction: column;
        flex: 1;
        //
    }

    .teaser-item-small__topline {
        margin-bottom: pxToRem(4);
        @include getFigmaStyle('Desktop/MD/Regular/Uppercase');
    }

    .teaser-item-small__headline {
        margin-bottom: 4px;
        @include getFigmaStyle('Desktop/H4/Regular/Default');

    }

    .teasertext {
        display: -webkit-box;
        /*! autoprefixer: off */
        -webkit-box-orient: vertical;
        /*! autoprefixer: on */
        -webkit-line-clamp: 2;
        margin: 12px 0 $spacing-xs;
        overflow: hidden;
        font-size: pxToRem(15);
        @include getFigmaStyle('Desktop/MD/Regular/Default');

    }

    .button__container {
        display: flex;
        justify-content: space-between;
        //
        margin-top: auto;
        //
    }

    .teaser__ranking {
        position: relative;
        display: flex;
        align-items: center;

        &::before {
            content: '';
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-right: $spacing-xxs;
            background-image: inline-svg('ranking');
            background-position: center center;
            background-size: 24px 24px;
            background-repeat: no-repeat;
        }
    }
    .chips {
        //margin-top: pxToRem(16);
        border-top: 1px solid $color-primary-400;
        padding-top: pxToRem(16);
        display: flex;
        flex-wrap: wrap;
        gap: 2px 24px;
        margin-bottom: 24px;
        @include getFigmaStyle('Desktop/SM/Regular/Default');

        li {
            position: relative;

            &:not(:last-child):after {

                content: '';
                position: absolute;
                right: -14px;
                top: 8px;
                display: block;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: $color-primary-800;
            }
        }
    }
    .teaser-item-small__readmore {
        position: absolute;
        bottom: 8px;
        right: 8px;
    }

}

.teaser__item--special {
    background-color: $color-background;

    .teaser-small__headline, .teasertext, .button__container, .button.button--primary--mixed:not(.button--link) {
        //color: $font-color-white;
    }
}

.teaser__image {
    position: relative;
}

.teaser__bookmark {
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 10;

    &:hover .bookmark__rememberme, &:focus .bookmark__rememberme {
        background-color: $color-primary;
    }
}

.teaser__bookmark .bookmark__text--rememberme, .teaser__bookmark .bookmark__text--remembered {
    display: none;
}

.teaser__bookmark {
    .bookmark__rememberme, .bookmark__remembered {
        width: 46px;
        height: 46px;
        background-color: rgba($color-black, .15);
        background-image: inline-svg('heart', $color-white, transparent);
        background-position: center center;
        background-size: 24px 24px;
        background-repeat: no-repeat;
        border-radius: 50%;
        transition: all .3s ease;
    }

    .bookmark__remembered {
        background-color: $color-primary;
    }

    [data-bookmark].bookmark__active .bookmark__rememberme {
        display: none;
        pointer-events: none;
    }

    [data-bookmark].bookmark__active .bookmark__remembered {
        display: block;
        pointer-events: none;
    }

    [data-bookmark]:not(.bookmark__active) .bookmark__rememberme {
        display: block;
        pointer-events: none;
    }

    [data-bookmark]:not(.bookmark__active) .bookmark__remembered {
        display: none;
        pointer-events: none;
    }
}

.teaser__price-container {
    display: flex;
    position: absolute;
    top: 10px;
    right: 8px;
    z-index: 10;
}

.teaser__price {
    display: inline-flex;
    align-items: center;
    height: 32px;
    padding: 5px 20px 0px;
    background-color: $color-white;
    font-size: pxToRem(17);
    font-weight: 400;
    color: #000;
    border-radius: pxToRem(16);
    span.price__price {

        @include getFigmaStyle('Desktop/H6/Medium/Default');
    }

    &--old {
        //display: none;
        z-index: 2;
        height: 36px;
        padding-right: 28px;
        margin-right: -15px;
        font-size: pxToRem(15);
        font-weight: 400;
        //color: $font-color-white;
        text-decoration: line-through;
        background-color: rgba($color-black, .2);
        border-radius: 18px 0 0 18px;
    }

    &--new {
        //display: none;
        z-index: 5;
        height: 36px;
        font-size: pxToRem(20);
        //color: $font-color-white;
        background-color: $color-primary;
        border-radius: 18px 0 0 18px;
    }

    .teaser__price {
        padding: initial;
    }
}

.meta-price:not(.active), .js-meta-price:not(.active), .js-meta-price-old:not(.active), .js-meta-price-new:not(.active) {
    display: none;
}

.teaser__angebot {
    position: absolute;
    bottom: 16px;
    left: 0;
    z-index: 10;
    display: inline-flex;
    align-items: center;
    min-height: 32px;
    padding: 3px 20px;
    background-color: $color-primary;
    font-weight: 600;
    //color: $font-color-white;
    border-radius: 0 16px 16px 0;
    max-width: 80%
}

.teaser__luxury {
    position: absolute;
    right: 0;
    bottom: $spacing-xs;
    z-index: 5;
    width: 42px;
    height: 32px;
    background-color: #B4A278;

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 24px;
        display: block;
        width: 42px;
        height: 42px;
        background-color: $color-white;
        background-image: inline-svg('crown', #C3AF82, transparent);
        background-position: center;
        background-size: 24px 24px;
        background-repeat: no-repeat;
        border: 3px solid #A4936D;
        border-radius: 50%;
    }
}

.teaser__facts {
    display: flex;
    justify-content: center;
    margin: 12px auto;

    li:not(:last-child) {
        margin-right: $spacing-xs;
    }
}

.button.button--small.button--primary--mixed.teaser__readmore {
    margin-left: auto;
}


.teaser-small__onlyalternativetimes {
    margin: 20px 0 16px -16px;
    color: #fff;
    padding: pxToRem(9) 16px pxToRem(9) 50px;
    font-weight: 400;
    font-size: pxToRem(15);
    width: calc(100% + 32px);
    position: relative;
    background: $color-primary;

    &:before {
        content: '';
        display: flex;
        position: absolute;
        left: 16px;
        top: pxToRem(9);
        width: 23px;
        height: 23px;
        background-image: inline-svg('alert-circle', $color-white, transparent,);
        background-repeat: no-repeat;

    }
}


.teaser-small__offer-hl {
    margin-top: pxToRem(22);
    margin-bottom: pxToRem(8);
    font-size: pxToRem(16);
    font-weight: 600;
    position: relative;

    &:before {
        position: absolute;
        content: '';
        width: calc(100% + 32px);
        left: -16px;
        top: -10px;
        background: $color-primary;
        height: 1px;
        display: block;
    }
}

.teaser-small__offer {
    padding-left: 22px;
    position: relative;
    font-size: pxToRem(12);
    line-height: pxToRem(18);
    //margin-top: pxToRem(10);
    margin-bottom: pxToRem(8);

    &:before {
        content: '';
        display: flex;
        position: absolute;
        left: 0;
        top: 9px;
        width: 12px;
        height: 9px;
        background-image: inline-svg('check02', $color-secondary, transparent,);
        background-repeat: no-repeat;
    }
}

.teaser__item--special {
    .teaser-small__offer, .teaser-small__offer-hl {
        color: #fff;
    }

    .teaser__angebot {
        background-color: #C3AF82;
    }

    .button.button--primary--mixed.button--small:not(.button--link)::after {
        background-color: #C3AF82;
    }

    .button.button--primary--mixed.button--small:not(.button--link):focus::after, .button.button--primary--mixed.button--small:not(.button--link):hover::after {
        background-color: $color-primary-hover;
    }
}

.teaser-small__offer--alt {
    font-weight: 400;
    font-size: pxToRem(15);
    line-height: pxToRem(18);
}

.teaser-small__offer-date {
    font-size: pxToRem(15);
    display: inline-block;
    padding-right: 7px;

}

.teaser-small__offer-price-old {
    font-weight: 600;
    font-size: pxToRem(15);
}

.teaser-small__offer-price-new {
    color: $color-primary !important;
}

.teaser-small__offer-txt {
    font-size: pxToRem(12);
    display: inline-block;
    padding-left: 5px;
}

.teaser__angebot.js-meta-offer:empty {
    padding: initial;
}

/* || CTA-Teaser */

.teaser--cta {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: pxToRem(36) pxToRem(16) pxToRem(16);
    text-align: center;
    min-height: 550px;

    .teaser-cta__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
    }

    .teaser-cta__topline, .teaser-cta__headline, .teaser-cta__content {
        color: $color-white;
    }

    .teaser-cta__topline {
        position: absolute;
        top: 36px;
        left: 0;
        display: inline-block;
        padding: pxToRem(3) pxToRem(20);
        font-size: pxToRem(20);
        font-weight: 600;
        background-color: $color-primary;
        border-radius: 0 23px 23px 0;
    }

    .teaser-cta__headline {
        position: relative;
        font-size: pxToRem(36);
        font-weight: 600;

        &::after {
            content: '';
            position: relative;
            display: block;
            width: 60px;
            height: 1px;
            margin: auto;
            margin-top: pxToRem(20);
            margin-bottom: pxToRem(36);
            background-color: $color-primary;
        }
    }

    .teaser-cta__copy {
        font-size: pxToRem(20);
        font-weight: 600;
        color: $color-white;
    }

    .button__container {
        display: flex;
        justify-content: flex-end;
        margin-top: auto;
    }
}

.teaser-cta--luxus {
    background-color: #5E020A;
}

.teaser-cta--lastminute {
    background-color: #62348B;
}

@media (min-width: 1500px) {
    .teaser--cta {
        min-height: 650px;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .teaser--cta {
        min-height: 600px;
    }
}

@include media-breakpoint-down(lg) {
    .teaser--cta {
        min-height: 800px;
    }
}

@include media-breakpoint-down(md) {
    .teaser--cta {
        min-height: 715px;
    }
}

@include media-breakpoint-down(sm) {
    .teaser--cta {
        .teaser-cta__headline {
            font-size: pxToRem(26);
        }

        .teaser-cta__topline, .teaser-cta__copy {
            font-size: pxToRem(16);
        }
    }

    .teaser--cta {
        min-height: 550px;
    }
}