/** Auto generated vars from gulpfile.js => src/config/shared-vars.json **/

$sx-breakpoints: (
        'xs': 0px,
        'sm': 576px,
        'md': 768px,
        'lg': 992px,
        'xl': 1200px,
        'xxl': 1440px
);

$sx-breakpoint-xs: 0px;
$sx-breakpoint-sm: 576px;
$sx-breakpoint-md: 768px;
$sx-breakpoint-lg: 992px;
$sx-breakpoint-xl: 1200px;
$sx-breakpoint-xxl: 1440px;

