/*
  * Use only local fonts!!
  * Preload only woff2 in src/Templates/element/html/head.php
  * Preloader Example: <link rel="preload" href="/src/theme/default/font/***.woff2" as="font" type="font/woff2" crossorigin>
  * IMPORTANT!!!! All webfonts must be converted with https://transfonter.org/ and the option "Fix vertical metrics"
*/

@font-face {
    //https://web.dev/font-display/?utm_source=lighthouse&utm_medium=devtools
    font-display: swap; //avoid showing invisible text
    //font-family: "???";
    //src: url("/src/theme/default/font/???");

}

/*

Copyright 2020 The Poppins Project Authors (https://github.com/itfoundry/Poppins)

This Font Software is licensed under the SIL Open Font License, Version 1.1.
This license is copied below, and is also available with a FAQ at:
http://scripts.sil.org/OFL

 */

@font-face {
    font-family: 'Poppins';
    src: url('/dist/font/Poppins-Regular.woff2') format('woff2'),
    url('/dist/font/Poppins-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('/dist/font/Poppins-Bold.woff2') format('woff2'),
    url('/dist/font/Poppins-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

/*

Copyright 2016 Zeynep Akay

This Font Software is licensed under the SIL Open Font License, Version 1.1.
This license is copied below, and is also available with a FAQ at:
http://scripts.sil.org/OFL

 */

@font-face {
    font-family: 'Rakkas';
    src: url('/dist/font/Rakkas-Regular.woff2') format('woff2'),
    url('/dist/font/Rakkas-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}