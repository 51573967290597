@import "@sx-core/src/scss/element/teaser.scss";

.teaser {
    position: relative;
    z-index: 1;
    margin-top: $modulspacing-desktop-s;
    margin-bottom: $modulspacing-desktop-m;


    &__price-container {
        position: absolute;
        top: 8px;
        right: 8px;
    }

    &__price {
        padding: pxToRem(6) pxToRem(12) pxToRem(4);
        color: $color-black;
        background-color: $color-white;



    }

    &__grid {
        row-gap: pxToRem(48);
    }

    .teaser__item {
        &:nth-child(1), &:nth-child(2), &:nth-child(3) {
            align-self: flex-end;
        }
    }
}

.teaser-item {
    position: relative;
    display: block;
    border-radius: $border-radius;
    overflow: hidden;
    @include getFigmaStyle('Shadow/MD');
    transition: all .3s ease;

    &__image {
        .image {
            position: relative;
            &:before {
                content: '';
                display: block;
                background: rgba(0, 0, 0, 0.10);
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                z-index: 1;
            }
        }
    }

    @include media-breakpoint-up(md) {
        &:hover, &:focus {
            box-shadow: 0 4px 36px 0 rgba(0, 0, 0, 0.20);

            .teaser-item__readmore .button--icon-only {
                background-color: $color-primary-600;
            }
        }
    }

    &__body {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: pxToRem(24) pxToRem(52) pxToRem(24) pxToRem(24);
        color: $color-white;
        z-index: 10;
    }

    &__headline.headline {
        margin-bottom: pxToRem(4);
        color: $color-white;
        @include getFigmaStyle('Desktop/H4/Regular/Default');
    }

    &__readmore {
        position: absolute;
        bottom: 8px;
        right: 8px;
    }
}

@include media-breakpoint-down(md) {
    .teaser {
        &__grid {
            row-gap: pxToRem(16);
        }
    }
}