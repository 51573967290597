@import "@sx-core/src/scss/element/header.scss";

.header {
    position: fixed;
    height: pxToRem(104);
    background-color: $color-white;
    @include getFigmaStyle('Shadow/MD');
}

.header > .container > .grid {
    width: 100%;
}

.header__logo {
    max-width: pxToRem(288);

    &--initial {
        height: auto;
    }

    &--scrolled {
        display: none;
    }
}

.header__booking-btn {
    width: auto;
    height: auto;
}

.header__language {
    margin-right: pxToRem(36);

    ul {
        width: auto;
        margin: initial;
    }

    .language__item {
        position: relative;
        display: inline-block;
        padding: initial;
        color: $color-primary-1200;
        @include getFigmaStyle('Desktop/MD/Medium/Uppercase');
    }

    ul > li .language__item {
        padding-bottom: pxToRem(4);
        transition: all .3s ease;

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            display: inline-block;
            width: 0;
            height: 2px;
            background-color: $color-secondary-800;
            transition: all .3s ease;
        }

        &--current {
            transform: translateY(-2px);

            &::before {
                width: 100%;
            }
        }

        @include media-breakpoint-up(md) {
            &:hover, &:focus {
                transform: translateY(-2px);
                &::before {
                    width: 100%;
                }
            }
        }
    }

    ul > li:last-child {
        margin-left: pxToRem(16);
    }

    ul > li:not(:last-child)::after {
        content: '';
        position: absolute;
        right: -10px;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: pxToRem(38);
        margin: initial;
        background-color: $color-primary-200;
    }
}

.js-nav-scrolled {
    .header {
        height: pxToRem(80);
        @include getFigmaStyle('Shadow/MD');
        transform: translateY(-100%);
    }

    .header__logo--initial {
        display: none;
    }

    .header__logo--scrolled {
        display: block;
    }
}

.js-nav-scrolled02 {
    .header {
        transform: translateY(0);
    }
}

@include media-breakpoint-down(xl) {
    .header__language {
        margin-right: initial;
    }
}

@include media-breakpoint-down(md) {
    .header {
        height: 88px;

        &__logo {
            max-width: pxToRem(225);

            &--scrolled {
                max-width: pxToRem(48);
            }
        }

        &__booking-btn.button {
            display: none;
            padding: pxToRem(4) pxToRem(12) pxToRem(2);
        }
    }

    .js-nav-scrolled {
        .header {
            height: 64px;

            &__booking-btn.button {
                display: block;
            }
        }
    }
}