
[sx-form-visibility]:not(.visible) {
    display: none !important;
}

[data-page] [data-id]:not(.visible) {
    display: none;
}

[sx-sqrl]:not(.sx-sqrl) {
    visibility: hidden;
}

