
* {
    outline: none !important;
}

*, *::before, *::after {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

html, body {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: $base-font-size;
    font-variant-numeric: normal;
    font-feature-settings: normal;
    line-height: 1.5;
}

body {
    overflow-x: hidden;
    font-family: $font-family-primary;
    font-weight: 400;
    color: $font-color;
    background-color: #f3f1e8;
}

.css-transitions-only-after-page-load *:not(img,picture) {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

.wrapper {
    visibility: visible !important;
    max-width: 100vw;
}

a {
    color: $color-primary-800;
    text-decoration: none;
}

address {
    font-style: normal;
}

.text-center {
    text-align: center;
}

@include media-breakpoint-down(md) {
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        hyphens: auto;
    }
}





