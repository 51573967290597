.asp {
    margin-top: $modulspacing-desktop-m;
    margin-bottom: $modulspacing-desktop-m;

    &__phone, &__email {
        display: flex;
        align-items: center;
        color: $color-black;

        &::before {
            content: '';
            position: relative;
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-right: pxToRem(8);
            background-position: center;
            background-size: 24px;
            background-repeat: no-repeat;
        }
    }

    &__phone {
        margin-top: pxToRem(32);

        &::before {
            background-image: inline-svg('phone', $color-primary-800, transparent);
        }
        position: relative;

        &:after {
            content: '';
            position: absolute;
            display: block;
            bottom: -2px;
            left: 31px;
            transition: all .4s ease;
            width: 0px;
            height: 1px;
            background: #000;

        }

        &:hover:after {
            width: calc(100% - 31px);
        }
    }

    &__email {
        margin-top: pxToRem(12);



        &::before {
            background-image: inline-svg('mail', $color-primary-800, transparent);
        }
        position: relative;

        &:after {
            content: '';
            position: absolute;
            display: block;
            bottom: -2px;
            left: 31px;
            transition: all .4s ease;
            width: 0px;
            height: 1px;
            background: #000;

        }

        &:hover:after {
            width: calc(100% - 31px);
        }
    }


    &__contact-btn {
        margin-top: pxToRem(32);
    }
}

.asp-item {
    &__image .image {
        border-radius: 50%;
        overflow: hidden;
    }

    &__body {
        display: flex;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}
@include media-breakpoint-down(md) {
    .asp {
        margin-top: $modulspacing-mobile-m;
        margin-bottom: $modulspacing-mobile-m;
        a.button {
            width: 100%;
        }
    }
    .asp-item__image {
        margin-bottom: pxToRem(36);
    }
}