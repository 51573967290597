
.message--error h3 {
    color: $color-danger;
}

.message--warning h3 {
    color: $color-warning;
}

.message--success h3 {
    color: $color-success;
}

.message--info h3 {
    color: $color-info;
}
