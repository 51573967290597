/* || Abstände */

#fewo section {
    margin: $spacing-xxl 0;

    h5 {
        color: $color-black;
    }
}

/* || KV Galerie */

.css-grid--unit__gallery {
    display: grid;
    position: relative;
    grid-template-columns: repeat(24, 1fr);
    grid-auto-rows: auto auto auto auto auto;
    column-gap: 8px;
    row-gap: 8px;
    margin-bottom: 0 !important;


    .keyvisual--fewo {
        display: none;
        margin-bottom: 0 !important;

        &:nth-child(1) {
            grid-column: 1/span 14;
            grid-row: 1/6;
            max-height: inherit;
            transform: translateY(0);
            display: block;
        }

        &:nth-child(2) {
            grid-column: 15 / span 5;
            grid-row: 1 / span 2;
            max-height: inherit;
            transform: translateY(0px);
            display: block;
        }

        &:nth-child(3) {
            grid-column: 15 / span 5;
            grid-row: 3 / span 3;
            max-height: inherit;
            transform: translateY(0px);
            display: block;
        }

        &:nth-child(4) {
            grid-column: 20 / span 5;
            grid-row: 1 / span 3;
            max-height: inherit;
            transform: translateY(0px);
            display: block;
        }

        &:nth-child(5) {
            grid-column: 20 / span 5;
            grid-row: 4  / span 2;
            max-height: inherit;
            transform: translateY(0px);
            display: block;
        }
    }
}

.button.btn-gallery-more {
    position: absolute;
    bottom: $spacing-s;
    left: inherit;
}

/* || Toolbar Unit */

.toolbar--unit {
    opacity: 1;
    pointer-events: auto;
    border-bottom: 1px solid #E6DCD0;

    li.active .button.button--tertiary--mixed.button--small::after {
       background-image: inline-svg('arrow-down', $color-secondary-hover, transparent);
        border-color: $color-secondary-hover;
    }

    .toolbar__left {
        margin-right: $spacing-l;
    }

    .toolbar__right {
        margin-left: $spacing-l;
    }
}

.unit-menu__buttons a {
    display: flex;
    align-items: center;
    font-size: pxToRem(17);
    //color: $font-color-black;
    cursor: pointer;

    &.rememberme::after {
        content: "";
        position: relative;
        width: 32px;
        height: 32px;
        margin-left: 8px;
        background-position: 50%;
        background-size: 18px 18px;
        background-repeat: no-repeat;
        transition: all .3s ease;
        border: 1px solid $color-secondary;
        border-radius: 50%;
    }
}

[data-bookmark].bookmark__active .bookmark__rememberme {
    display: none;
    pointer-events: none;
}

[data-bookmark].bookmark__active .bookmark__remembered {
    display: block;
    pointer-events: none;
}

[data-bookmark]:not(.bookmark__active) .bookmark__rememberme {
    display: block;
    pointer-events: none;
}

[data-bookmark]:not(.bookmark__active) .bookmark__remembered {
    display: none;
    pointer-events: none;
}

[data-bookmark].bookmark__active::after {
    background-color: $color-primary;
    background-image: inline-svg('heart', $color-white, transparent);
    border-color: $color-primary !important;
}

/* || ObjektID */

.unit__objektid {
    margin-bottom: 12px;
    font-size: pxToRem(15);
}

/* || Chips/Facts */

ul.chips--unit {
    padding-left: initial;
    margin-bottom: $spacing-xxs;
    list-style-type: none;

    li {
        padding-left: initial;
        margin-bottom: $spacing-xxs;
    }

    li span {


        &.ico__personen-max, &.ico__schlafzimmer, &.ico__grosse, &.ico__badezimmer, &.ico__kinder-willkommen, &.ico__wlan, &.ico__haustiere-erlaubt, &.ico__sauna {
            padding-left: initial;

            &::before {
                width: 32px;
                height: 32px;
                margin-right: $spacing-xxs;
                border: 1px solid $color-secondary;
                border-left: transparent;
                border-radius: 50%;
            }
        }
    }
}

/* || Introtext Unit */

.view-unit .introtext__text .content {
    margin-left: initial;
}

/* || Ausstattung: Akkordeons */

h2.fewo__ausstattung__hl {
    margin-bottom: 20px
}

.fewo__ausstattung .faq__content {
    padding: 12px 0 pxToRem(48);
}
.faq__content .article {
    margin: 0;
}


.fewo__ausstattung .vorteile__container {
    display: flex;

    &:not(:last-child) {
        margin-bottom: 24px;
    }

    h3 {
        flex: 1;
        font-size: pxToRem(17);
        line-height: 1.5;
    }

    .vorteile__rooms {
        display: flex;
        flex-wrap: wrap;
        flex: 2;
        margin: initial;
        column-gap: initial;

        li {
            width: 50%;
            margin-bottom: $spacing-xxs;
        }

        li:nth-child(odd) {
            //padding-right: $spacing-xl;
        }
    }

    @include media-breakpoint-down(md) {
        .vorteile__rooms li {
            width: 100%;
        }
    }
}

/* || Map */

#fewo .fewo__map {
    margin-bottom: $spacing-l;
}

.fewo__map .directions__map {
    height: auto;
    border-radius: 16px;
}

.fewo__map-area {
    padding: 24px 24px 28px;
    background-color: $color-tertiary-light;
    border-radius: 16px 16px 0 0;

    h3 {
        padding-bottom: $spacing-xs;
        font-size: pxToRem(20);
        border-bottom: 1px solid $color-primary;
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        padding-left: initial;
        margin: initial;
        list-style-type: none;
    }

    ul li {
        flex: 0 0 33%;
        padding-left: initial;
        &::before {
            content: none;
        }
    }

    ul li span {
        display: inline-block;
        width: 50%;
        font-weight: 600;
    }
}

/* || ASP */

#fewo .fewo__asp {
    margin-top: $spacing-l;
}

/*|| Angebot */

.fewo-section__booking {
    background-color: $color-tertiary-light;
    border-radius: 16px;
    padding: $spacing-l $spacing-xs;

    #fewo__booking, #fewo__form {
        margin: initial;
    }

    #fewo__form {
        margin-top: pxToRem(48);
    }
}

//#fewo__booking table {
//    border-radius: 16px;
//}

#fewo__booking {

    small {
        font-size: pxToRem(15);
        font-weight: 400;
    }

    td:not(:first-child) {
        white-space: nowrap;
    }

    tr:first-child, .fewo-tablehead {
        font-size: pxToRem(20);
        //background-color: $color-tertiary-light;
        //background-color: $color-white;
        border-bottom: 2px solid $color-primary-400;
        //border-radius: 16px;

        th {
            padding: $spacing-xs;
        }
    }

    tr th:first-child {
        border-radius: 16px 0 0 16px;
    }

    tr th:last-child {
        border-radius: 0 16px 16px 0;
    }

    tr th:nth-child(2), tr td:nth-child(2) {
        text-align: center;
    }

    tr:nth-last-child(2) {
        border-bottom: none;
    }

    tr:not(:last-child):not(:first-child):not(.fewo-tablehead) {
        border-color: $color-primary-200;
    }

    tr:last-child {
        background: $color-primary;
        color: $color-white;
        font-size: pxToRem(20);

        td {
            padding: pxToRem(28) pxToRem(16);
            font-weight: 700;
            &:nth-child(2) {
                text-align: right;
                @include getFigmaStyle('Desktop/H4/Regular/Default');
            }
        }

        td:first-child {
            border-radius: 16px 0 0 16px;
        }

        td:last-child {
            border-radius: 0 16px 16px 0;
        }
    }

    tr td, tr th {
        padding: 12px 16px;
        text-align: right;
        font-size: pxToRem(17);
        font-weight: 600;

        &:first-child {
            text-align: left;
        }
    }

    .formular-item__body {
        display: flex;
        justify-content: center;
    }

    .bootstrap-touchspin {
        border: 1px solid $color-primary-800;
        border-radius: 23px;
        align-items: center;
        transition: box-shadow .3s ease;

        @include media-breakpoint-up(md) {
            &:hover, &:focus {
                box-shadow: 0 0 0 3px $color-primary-400;
            }
        }

        & > input {
            background: transparent;
            border: none;
            width: 50px;
            text-align: center;
        }

        & > span {
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            background: transparent;
            margin: 8px;
            width: 20px;
            height: 20px;

            button {
                display: flex;
                align-items: baseline;
                justify-content: center;
                height: 20px;
                width: 20px;
                margin: 0 auto;
                font-size: pxToRem(16);
                color: $color-black;
                background: none;
                border: none;
                &.bootstrap-touchspin-down::after {
                    //background-color: $color-white;
                    background-color: $color-tertiary-light;
                    background-image: inline-svg('minus', $color-black, transparent);
                    opacity: 1;
                    border-radius: 50%;
                    top: initial;
                    right: initial;
                    transform: none;
                }

                &.bootstrap-touchspin-up::after {
                    //background-color: $color-white;
                    background-color: $color-tertiary-light;
                    background-image: inline-svg('add', $color-black, transparent);
                    opacity: 1;
                    border-radius: 50%;
                    top: initial;
                    right: initial;
                    transform: none;
                }
            }
        }

        input.sx-touch-spin {
            padding-top: pxToRem(4);
            font-weight: 600;
        }
    }
}

/* || Buchungsformular */

#fewo__form .formular {
    border-radius: $spacing-xs;
    //background: #F6F3EE;
    //padding: 24px;
    margin-top: $spacing-m;
}

.fewo__booking-bar {
    position: fixed;
    top: 50%;
    left: 0;
    z-index: 982;
    display: flex;
    flex-direction: row-reverse;
    transform: rotate(-90deg) translateX(-50%);
    transform-origin: left top;
    //border-radius: 0 0 16px 16px;

    li {
        padding: 2px $spacing-xs 3px;
        line-height: 14px;
        font-size: pxToRem(15);
        background-color: rgba(#1E1A15, .35);
        transition: all .3s ease;

        &:first-child {
            padding-right: 20px;
            border-radius: 0 0 16px 0;
        }

        &:last-child {
            padding-left: 20px;
            border-radius: 0 0 0 16px;
        }

        &.active {
            background-color: $color-primary;
        }
    }

    li a {
        display: inline-block;
        line-height: 14px;
        //color: $font-color-white;
    }

    li.bar__item--single {
        border-radius: 0 0 16px 16px;
        transition: border-radius .3s ease;
    }
}

/* || Angebots Box */
.fewo__specialoffer {
    background: #F8ECEC;
    border: 4px solid $color-primary;
    border-radius: 16px;
    padding: pxToRem(24);
    margin-top: 0px;
}
.fewo__specialoffer__hl {
    font-weight: 600;
    font-size: pxToRem(20);
    line-height: pxToRem(24);
    padding-bottom: pxToRem(10);
    border-bottom: 1px solid $color-primary;
    margin-bottom: pxToRem(16);
}
.fewo__specialoffer__content {
    display: flex;
    flex-wrap: wrap;
    gap: pxToRem(32);
    .teaser-small__offer {
        flex: 1 1 calc(50% - pxToRem(16));
        font-size: pxToRem(17);
        font-weight: 600;
        line-height: pxToRem(25);
        &:before {
            background-image: inline-svg('check02', $color-primary, transparent, );
        }
    }
    small {
        font-weight: 400;
    }
}
@include media-breakpoint-down(md) {
    .fewo__specialoffer__content {
        gap: pxToRem(12);
        .teaser-small__offer {
            flex: 1 1 100%;
        }
    }
}

/* || AGB-Modal */

.uk-modal-header, .uk-modal-footer {
    background: $color-tertiary-light;
}

.uk-modal-close-default {
    svg {
        display: none;
    }
}

.uk-sticky {
    z-index: 40;
}
.units__slider {
    background: $color-primary-300;
    margin-top: pxToRem(128);
    &.icon-bg-left-anker {
        &:before {
            top: 26%;
            transform: translate(-30%, -50%) scale(0.8)
        }
    }
    .article {
        margin-bottom: pxToRem(64);
    }
}
/* || Media Queries */

@media(max-width: 1370px) {
    .toolbar {
        padding: 0 20px;
    }
}

@include media-breakpoint-down(lg) {
    .col--fewo-stickyinfobox {
        z-index: 982;
    }
}

@include media-breakpoint-down(md) {
    .toolbar {
        padding: 0 0 0 20px;
    }

    .toolbar__right {
        padding-right: 20px;
    }

    .keyvisual.keyvisual--fewo {
        height: auto;
    }

    .css-grid--unit__gallery {
        column-gap: 4px;
        row-gap: 4px;

        .keyvisual--fewo {
            &:nth-child(1) {
                grid-column: 1/span 24;
                grid-row: 1/ span 1;
            }

            &:nth-child(2) {
                grid-column: 1 / span 12;
                grid-row: 2 / span 1;
            }

            &:nth-child(3) {
                display: none;
            }

            &:nth-child(4) {
                display: none;
            }

            &:nth-child(5) {
                grid-column: 13 / span 12;
                grid-row: 2  / span 1;
            }
        }
    }

    .button.btn-gallery-more {
        left: 12px;
        bottom: 12px;
    }

    #fewo .fewo__map {
        margin-top: $spacing-s;
    }
    #fewo .fewo__asp {
        margin-top: $spacing-s;
    }
}

@include media-breakpoint-down(sm) {
    .toolbar--unit {
        .toolbar__left, .toolbar__center {
            margin-right: $spacing-m;
        }
    }

    #fewo section {
        margin: pxToRem(56) 0;
    }

    #fewo__booking tr td, #fewo__booking tr th {
        padding-left: initial;
    }

    #fewo__form .formular {
        padding-left: initial;
        padding-right: initial;
    }

    .fewosfilter-popup--reduced {
        height: auto;
        width: calc(100vw - 16px);
    }

    .vorteile__container {
        flex-direction: column;

        h3 {
            margin-bottom: 24px;
        }
    }

    .fewo__ausstattung .vorteile__container .vorteile__rooms li {
        width: 100%;
        padding-left: 26px;
    }

    .fewo-container__map {
        display: flex;
        flex-direction: column-reverse;

        .directions__map {
            border-radius: 16px 16px 0 0;
        }
    }

    .fewo__map-area {
        border-radius: 0 0 16px 16px;
    }

    .fewo__map-area {
        ul {
            flex-direction: column;
        }

        li {
            display: flex;
            justify-content: space-between;
        }

        ul li span {
            width: auto;
        }
    }

    #fewo__booking {
        th {
            //color: transparent;
        }

        tr:first-child {
            display: none;
        }

        tr {
            display: flex;
            flex-wrap: wrap;
            align-items:center;

            &:last-child {
                flex-wrap: nowrap;
            }
        }

        tr td:first-child {
            flex: 1 1 100%;
        }

        tr td:last-child {
            margin-left: auto;
        }

        tr:last-child {
            border-radius: 16px;
        }
    }

    /* || Belegungskalender mobile */

    .belegungskalender {
        padding: 0 12px 12px !important;

        .mbsc-calendar-controls {
            margin-top: 16px;
        }
    }
}

.fewo-sticky__input--guests {
    background-image: inline-svg('arrow-form-down', $color-secondary, transparent);
    background-position: center right 12px;
    background-size: 32px 32px;
    background-repeat: no-repeat;

    @include media-breakpoint-up(md) {
        &:hover, &:focus {
            box-shadow: 0 0 0 3px $color-primary-400;
        }
    }
}

.fewo-sticky__input--date {
    background-image: inline-svg('arrow-form-down', $color-secondary, transparent);
    background-position: center right 12px;
    background-size: 32px 32px;
    background-repeat: no-repeat;

    @include media-breakpoint-up(md) {
        &:hover, &:focus {
            box-shadow: 0 0 0 3px $color-primary-400;
        }
    }
}

/* || KV */

.keyvisual--unit {
    .css-grid--unit__gallery.single-gallery {
        .keyvisual--unit {
            display: none;
        }

        .keyvisual--unit:nth-child(1) {
            display: block;
            grid-column: 1 / span 24;
        }
    }

    .button.btn-gallery-more {
        position: absolute;
        bottom: pxToRem(24);
        right: pxToRem(64);
        z-index: 10;

        & > span {
            &::after {
                background-image: inline-svg('plus-m', $color-white);
            }
            b {
                font-weight: 400;
            }
        }
    }

    .image {
        position: relative;
    }
}

.css-grid--unit__gallery {
    position: relative;
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    grid-template-rows: 1fr 1fr;
    column-gap: pxToRem(4);
    row-gap: pxToRem(4);

    .keyvisual--unit {
        display: none;

        &:nth-child(1) {
            display: block;
            grid-column: 1/span 8;
            grid-row: 1/span 1;
        }

        &:nth-child(2) {
            display: block;
            grid-column: 9 / span 8;
            grid-row: 1/span 1;
        }

        &:nth-child(3) {
            display: block;
            grid-column: 1 / span 8;
            grid-row: 2/span 1;
        }

        &:nth-child(4) {
            display: block;
            grid-column: 9 / span 8;
            grid-row: 2/span 1;
        }

        &:nth-child(5) {
            display: block;
            grid-row: 1/span 2;
            grid-column: 17 / span 8;
        }
    }
}

@include media-breakpoint-down(md) {
    .css-grid--unit__gallery {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        .keyvisual--unit {
            display: none !important;
            &:nth-child(1), &:nth-child(2), &:nth-child(3) {
                display: block !important;
                width: 100%;
            }

            &:nth-child(2), &:nth-child(3) {
                width: calc(50% - 4px);
            };


        }

    }
    .keyvisual--unit .button.btn-gallery-more  {
        left: inherit;
        right: 16px;
        bottom: 16px;
        padding: 12px;
        & > span {

            b {
                display: none;
            }
            &:after {
                margin-left: 0;
            }
        }
    }
}

/* || Chips */

ul.chips--unit {
    display: flex;
    flex-wrap: wrap;
    column-gap: pxToRem(8);
    row-gap: pxToRem(8);
    padding-left: initial;
    margin-top: pxToRem(32);
    margin-bottom: pxToRem(20);
    list-style-type: none;

    li {
        padding: pxToRem(9) pxToRem(16) pxToRem(3);
        border: 2px solid $color-primary-400;
        border-radius: 320px;
        font-size: 15px;
        &:before {
            display: none;
        }
    }
}

/* || Articles/Sections */

#fewo {
    margin-top: $modulspacing-desktop-m;
}

.article.article__unit--default {
    margin-top: initial;
    margin-bottom: $modulspacing-desktop-xs;
}

#fewo {
    section {
        h1, h2, h3, h4 {
            color: $color-primary-800;
        }

        h2 {
            @include getFigmaStyle('Desktop/H4/Regular/Default');
        }
    }

    .unit__content > section {
        margin-top: $modulspacing-desktop-s;
        margin-bottom: $modulspacing-desktop-s;
    }
}
@include media-breakpoint-down(md) {
    #fewo {
        margin-top: pxToRem(72);
    }
}
/* || Accordion */

.accordion__item {
    border-bottom: 2px solid $color-primary-400;
    .accordion-item__header h5 {
        margin-bottom: initial;
        @include getFigmaStyle('Desktop/LG/Regular/Default');
        padding: 16px;

    }
    .accordion-item__body {
        padding: 0 16px 16px 16px;
    }
}

/* || Buchung */

.fewo-section__booking {
    padding: pxToRem(24);
    background-color: $color-secondary-200;
    border-radius: $border-radius;

    .headline--topline {
        display: inline-block;
        margin-bottom: pxToRem(16);
    }
}

#fewo__booking {
    padding-bottom: pxToRem(48);
    margin-bottom: pxToRem(48);
    border-bottom: 1px solid $color-primary-800;

    table {
        margin-bottom: initial;
    }
}



@include media-breakpoint-down(sm) {
    ul.vorteile__rooms {
        column-count: 1;
    }
}

