@import "@sx-core/src/scss/element/footer.scss";

.footer {
    height: auto;
    padding: pxToRem(64) 0;
    color: $color-white;
    background-color: $color-primary-1100;

    a {
        color: $color-white;
    }

    & > .container, & > .container > .grid {
        height: auto;
    }

    &__top, &__bottom {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &__top {
        margin-bottom: pxToRem(36);
        align-items: flex-start;
    }

    &__logo {
        max-width: pxToRem(256);
    }

    &__navigation {
        display: flex;
        align-items: center;

        & > ul {
            display: flex;
            justify-content: flex-end;
        }

        & > ul > li {
            padding: 0 pxToRem(24);
        }

        & > ul > li a {
            position: relative;
            display: inline-block;
            padding: pxToRem(6) 0;
            color: $color-white;
            font-size: pxToRem(17);
            @include getFigmaStyle('Desktop/MD/Regular/Default');
            transition: all .3s ease;

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                display: inline-block;
                width: 0;
                height: 2px;
                background-color: $color-secondary-800;
                transition: all .3s ease;
            }

            @include media-breakpoint-up(md) {
                &:hover, &:focus {
                    color: $color-white;
                    transform: translateY(-2px);
                    &::after {
                        width: 100%;
                    }
                }
            }
        }
    }

    &__bottom {
        align-items: flex-end;
        flex-wrap: wrap;
        padding-top: pxToRem(48);
        column-gap: pxToRem(48);
        row-gap: pxToRem(36);
    }

    &__cookies {
        position: relative;
        display: flex;
        align-items: center;
        text-decoration: underline;
        text-decoration-color: transparent;
        transition: all .3s ease;

        &::before {
            content: '';
            display: inline-block;
            width: 36px;
            height: 36px;
            margin-right: pxToRem(12);
            background-image: inline-svg('cookie', $color-white, transparent);
            background-position: center;
            background-size: 24px 24px;
            background-repeat: no-repeat;
            border: 2px solid $color-secondary-800;
            border-radius: 50%;
        }

        @include media-breakpoint-up(md) {
            &:hover, &:focus, &:active {
                text-decoration-color: $color-white;
            }
        }

        &.footer__cookies--mobile {
            color: $color-white;
            text-decoration: none;

            &::before {
                background-image: inline-svg('cookie', $color-white, transparent);
                border-color: $color-white;
            }
        }
    }
}

.contact {
    @include getFigmaStyle('Desktop/MD/Regular/Default');

    a {
        display: block;
        @include getFigmaStyle('Desktop/SM/Bold/Default');
    }

    span {
        display: inline-block;
        margin-left: pxToRem(12);
        @include getFigmaStyle('Desktop/SM/Regular/Default');
        font-size: pxToRem(16);
    }

    .contact__title {
        @include getFigmaStyle('Mobile/H5/Medium/Default');
        margin-bottom: pxToRem(4);
    }

    .contact__subline {
        margin-bottom: pxToRem(20);
    }

    .contact__address {
        margin-bottom: pxToRem(12);
    }

    .contact__phone {
        position: relative;
    }

    .contact__email {
        position: relative;
    }
}

@include media-breakpoint-down(lg) {
    .footer {
        padding-bottom: pxToRem(100);
    }
}

@include media-breakpoint-down(md) {
    .footer {
        padding: pxToRem(36) pxToRem(8) pxToRem(100);

        &__top {
            flex-direction: column-reverse;
        }

        &__navigation {
            padding-bottom: pxToRem(28);
            margin-bottom: pxToRem(0);
            padding: 0;


            & > ul {
                flex-wrap: wrap;
                justify-content: flex-start;
                row-gap: pxToRem(8);
                column-gap: pxToRem(16);
                margin: pxToRem(36) 0 0 0 ;

                & > li {
                    padding: 0;
                     a {
                        font-size: pxToRem(14);
                    }
                }
            }
        }

        &__bottom {
            flex-direction: column;
            align-items: flex-start;
            row-gap: pxToRem(36);
            padding-top: initial;
            border-top: none;
        }
        &__contact {
            order: +1;
            font-size: pxToRem(14);
        }

        &__cookies {
            margin-left: initial;
            margin-right: auto;
        }

        &__socials {
            flex-wrap: wrap;
            margin-left: initial;
        }
    }

    .contact {
        a:not(:first-child) {
            margin-top: pxToRem(8);
        }

        .contact__address {
            margin-bottom: pxToRem(12);
        }

        .contact__email {
            span {
                text-decoration: initial;
            }
        }
        span {
            font-size: pxToRem(14);
        }
    }

}