.header__navigation ul {
    width: calc(100% + 16px);
    margin: 0 -8px;

    ul {

        @include media(lg) {
            opacity: 0;
            pointer-events: none;
            display: block;
            position: absolute;
            top: 52px;
            transition: opacity .4s ease;
            &:before {
                content: '';
                position: absolute;
                height: 31px;
                width: 100%;
                top: -31px;
            }
        }
    }
}

.header__navigation ul > li {
    display: inline-block;
    padding: 0 8px;
    position: relative;

    &:hover ul {
        opacity: 1;
        pointer-events: auto;
    }
}

.header__navigation ul ul li {
    display: block;
}

.burger {
    display: none;
    transition: all 1s ease;
}

.plus {
    display: none;
    width: 60px;
    height: 60px;
    position: absolute;
    right: 10px;
    top: 0;
    z-index: 100;
    transition: transform .4s ease
}

.plus:before {
    transition: all .6s ease;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    content: '';
    width: 60px;
    height: 60px;
    background-image: url('/assets/img/plus.svg');
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: 20px 20px;
    background-size: 20px 20px;
}

@include media-breakpoint-down(md) {
    .burger {
        display: block;
        position: absolute;
        right: 10px;
        top: 15px;
        z-index: 600;
    }
    .plus {
        display: block;
    }
    .js-subnav-open .plus {
        transform: rotate(-45deg);
    }
    .js-nav-open {
        .header__navigation {
            left: 0;
        }
    }
    .header__navigation {
        position: fixed;
        z-index: 550;
        left: -100vw;
        top: 0;
        width: 100%;
        min-height: 100vh;
        -ms-overflow-style: scrollbar;
        overflow-style: scrollbar;
        display: flex;
        justify-content: center;
        padding-top: 100px;
        background: rgba(0, 0, 0, .8);
        transition: all .6s ease;

        ul {
            display: inline-block;
            width: 100%;

            li {
                display: block;
                text-align: center;
                font-size: pxToRem(30);
                padding: 10px;

                a {
                    color: $color-white;
                }
            }
        }

        ul ul {
            display: none;

            li {
                font-size: pxToRem(20);
                padding: 2px 0;
            }
        }
    }

}
