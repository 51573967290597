/**
 * Slider
 */

//.slider__navigation {
//    width: 100%;
//    height: 0;
//}
//
//.slider__prev,
//.slider__next {
//    position: absolute;
//    top: calc(50% - 25px);
//    display: block;
//    width: 50px;
//    height: 50px;
//    background-color: rgba($color-black, 0.3);
//    background-size: 16px 16px;
//    background-position: center center;
//    background-repeat: no-repeat;
//}
//
//.slider__prev {
//    left: 0;
//    background-image: inline-svg('angle-left', $color-white);
//}
//
//.slider__next {
//    right: 0;
//    background-image: inline-svg('angle-right', $color-white);
//}

//.swiper-button__container {
//    display: flex;
//    justify-content: flex-end;
//    margin-top: $spacing-l;
//}
//
//.swiper-button-prev, .swiper-button-next {
//    position: relative;
//    left: 0;
//    right: 0;
//    width: auto;
//    height: 64px;
//    margin-top: 0;
//    //background-image: inline-svg('angle-l', $color-white, transparent);
//    //background-position: center center;
//    //background-size: 46px 46px;
//    //background-repeat: no-repeat;
//    //border: 1px solid $color-secondary;
//    //border-radius: 50%;
//    transition: all .3s ease;
//
//    &::after {
//        content: none;
//    }
//
//    &:hover {
//        //background-color: $color-secondary;
//    }
//}
//
//.swiper-button-next, .swiper-button-prev {
//    //transform: rotate(180deg);
//    span {
//        display: flex;
//        align-items: center;
//        font-size: pxToRem(17);
//        font-weight: 500;
//    }
//}
//
//.swiper-button-prev {
//    margin-right: $spacing-m;
//
//    span::before {
//        content: '';
//        position: relative;
//        display: inline-block;
//        width: 40px;
//        height: 40px;
//        margin-right: $spacing-xs;
//        background-image: inline-svg('angle-left-slim', $color-secondary);
//        background-position: center center;
//        background-size: 15px 15px;
//        background-repeat: no-repeat;
//        border: 1px solid $color-secondary;
//        border-radius: 50%;
//        transition: all .3s ease;
//    }
//
//    &:hover span::before {
//        background-image: inline-svg('angle-left-slim', $color-secondary-hover);
//        border-color: $color-secondary-hover;
//    }
//}
//
//.swiper-button-next {
//    span::after {
//        content: '';
//        position: relative;
//        display: inline-block;
//        width: 40px;
//        height: 40px;
//        margin-left: $spacing-xs;
//        background-image: inline-svg('angle-right-slim', $color-secondary);
//        background-position: center center;
//        background-size: 15px 15px;
//        background-repeat: no-repeat;
//        border: 1px solid $color-secondary;
//        border-radius: 50%;
//        transition: all .3s ease;
//    }
//
//    &:hover span::after {
//        background-image: inline-svg('angle-right-slim', $color-secondary-hover);
//        border-color: $color-secondary-hover;
//    }
//}

///* || Slider-Buttons */
//
//button.splide__arrow {
//    width: 64px;
//    height: 64px;
//    border-radius: 50%;
//    background-color: rgba(#1E1A15, .42);
//    background-position: center center;
//    background-size: 46px 46px;
//    background-repeat: no-repeat;
//    transition: all .3s ease;
//
//    @include media-breakpoint-up(lg) {
//        &:hover, &:focus {
//            background-color: rgba(#1E1A15, .64);
//        }
//    }
//
//    &:disabled {
//        display: none;
//    }
//
//    svg {
//        display: none;
//    }
//
//    &.splide__arrow--next {
//        right: calc(48px + 32px);
//        background-image: inline-svg('arrow-right', $color-white, transparent);
//    }
//
//    &.splide__arrow--prev {
//        left: calc(48px + 32px);
//        background-image: inline-svg('arrow-left', $color-white, transparent);
//    }
//}
//
//.splide__pagination {
//    display: none;
//}
//
///* || Teaser-Slider */
//
//.teaser--slider .teaser__item {
//    position: relative;
//    height: 100%;
//    display: block;
//    width: 100%;
//}
//
//.teaser-slider {
//    position: relative;
//    overflow-x: hidden;
//}
//
//.teaser__swiper {
//    width: calc(100vw - ((100vw - 100%) / 2));
//}
//
//.swiper-slide {
//    position: relative;
//    flex-shrink: 0;
//    height: 100%;
//}
//
///* || Teaser Slider small */
//
//.teaser--small, .teaser__slider--small {
//    padding: 80px 0;
//    background-color: $color-tertiary;
//}
//
//.teaser__slider--small {
//    padding: calc(80px - 36px) 0;
//}
//
//.teaser__slider--small > .splide > .splide__track > .splide__list > .splide__slide, .teaser__slider--small .uk-switcher .splide > .splide__track > .splide__list > .splide__slide {
//    padding-top: $spacing-m;
//    padding-bottom: $spacing-m;
//}
//
///* Object-Slider */
//
//.category__tabs {
//    justify-content: center;
//    width: calc(100% - 48px);
//    margin: 0 auto;
//    border-bottom: 1px solid #D5C9AF;
//
//    li {
//        padding: 0 $spacing-s;
//
//        &::after {
//            content: '';
//            position: absolute;
//            bottom: -1px;
//            left: 50%;
//            transform: translateX(-50%);
//            display: block;
//            width: 0;
//            height: 1px;
//            background-color: $color-primary;
//            opacity: 0;
//            transition: all .4s ease;
//        }
//
//        &:hover::after, &:focus::after, &.uk-active::after {
//            opacity: 1;
//            width: calc(100% - 56px);
//        }
//
//        a {
//            padding: initial;
//            padding-bottom: $spacing-xs;
//            font-size: pxToRem(17);
//            color: $font-color;
//            transition: transform .3s ease;
//        }
//
//        &:hover a, &:focus a, &.uk-active a {
//            color: $font-color;
//            transform: translateY(-5px);
//            transition: all .3s ease;
//        }
//    }
//
//}
//
//.teaser__slider--small .uk-switcher .teaser {
//    margin: initial;
//}
//
//.cancellation-info + .object-slider {
//    margin-top: initial;
//}
//
//.object-slider {
//    padding: initial;
//    padding-top: $spacing-xl;
//    margin-top: $spacing-xxl;
//}
//
//.object-slider .teaser__slider--small {
//    padding-top: 0;
//}
//
//.headline--object-slider {
//    margin-bottom: $spacing-s;
//}
//
//.content--object-slider {
//    margin-left: $spacing-xl;
//    margin-bottom: $spacing-xl;
//}
//
//.teaser__slider--small.object-slider {
//    .fewo_votings {
//        padding-bottom: pxToRem(64);
//    }
//}
//
//.category__tabs {
//    margin-top: pxToRem(56);
//}
//
///* || Media Queries */
//
//@include media-breakpoint-up(lg) {
//    .object-slider {
//        button.splide__arrow.splide__arrow--prev {
//            left: -28px;
//        }
//
//        button.splide__arrow.splide__arrow--next {
//            right: -28px;
//        }
//    }
//}
//
//@include media-breakpoint-down(lg) {
//    .object-slider .container.container--fluid {
//        padding-left: 0;
//        padding-right: 0;
//    }
//}
//
//@include media-breakpoint-down(md) {
//    .teaser__slider--small.object-slider {
//        .teaser__slider--small {
//            padding-bottom: pxToRem(20);
//        }
//
//        &::before {
//            content: none;
//        }
//
//        .fewo_votings {
//            padding-bottom: initial;
//
//            &_detail {
//                text-align: center;
//            }
//        }
//    }
//
//    .teaser__slider {
//        padding: $spacing-l 0;
//        margin: 56px 0;
//
//        &::before {
//            width: 100%;
//        }
//    }
//
//    .teaser--small, .teaser__slider--small {
//        padding: $spacing-l 0;
//    }
//
//    .teaser__slider--small > .splide > .splide__track > .splide__list > .splide__slide {
//        padding-top: initial;
//        padding-bottom: initial;
//    }
//}
//
//@include media-breakpoint-down(sm) {
//    .splide__arrow {
//        display: none;
//    }
//
//    .teaser__slider .splide__pagination, .teaser__slider--small .splide__pagination {
//        bottom: 0;
//        transform: translateY(100%);
//        display: flex;
//        padding: initial;
//        padding-top: 12px;
//
//        li {
//            width: 8px;
//            height: 8px;
//
//            &:not(:last-child) {
//                margin-right: 4px;
//            }
//        }
//
//        li button {
//            width: 8px;
//            height: 8px;
//            border-radius: 50%;
//            background: #DED4C1;
//            transform: none !important;
//            opacity: 1;
//
//            &.is-active {
//                background: $color-primary;
//            }
//        }
//    }
//
//    .category__tabs li {
//        margin-bottom: $spacing-xxs;
//    }
//
//    .content--object-slider {
//        margin-left: initial;
//    }
//
//    .teaser__slider--small.object-slider {
//        .splide__pagination {
//            padding-top: initial;
//        }
//
//        .teaser__slider--small {
//            padding-bottom: pxToRem(44);
//        }
//
//        .fewo_votings {
//            padding-bottom: initial;
//            .fewo_votings_detail {
//                margin-left: initial;
//            }
//        }
//    }
//}

.slider {
    position: relative;
    z-index: 1;
    margin-top: $modulspacing-desktop-m;
    margin-bottom: $modulspacing-desktop-m;
}

.splide__pagination {
    display: none;
}

.splide__arrow {
    width: pxToRem(64);
    height: pxToRem(64);
    background-color: $color-primary-800;
    border-radius: 50%;
    background-position: center;
    background-size: pxToRem(40) pxToRem(40);
    background-repeat: no-repeat;

    svg {
        display: none;
    }

    &--prev {
        left: 36px;
        background-image: inline-svg('arrow-long-left', $color-white);
    }

    &--next {
        right: 36px;
        background-image: inline-svg('arrow-long-right', $color-white);
    }
}

.teaser-small-slider .splide__track {
    padding-top: pxToRem(36);
    padding-bottom: pxToRem(36);
}