@import "@sx-core/src/scss/element/keyvisual.scss";

.keyvisual {
    position: relative;
    z-index: 1;
    margin-top: pxToRem(104);
    overflow: initial;

    .keyvisual {
        padding: initial;
        margin-top: initial;
        overflow: hidden;
    }

    .splide__arrows {
        display: none;
    }
}

.keyvisual__empty {
    height: pxToRem(104);
}

.keyvisual__big {
    height: calc(100vh - 104px - 48px);

    .keyvisual__big {
        height: 100%;
    }

    ul {
        height: 100%;
    }
}



/*** || Claim ***/

.claim {
    position: absolute;
    top: 72px;
    right: 64px;
    display: flex;
    flex-direction: column;


    width: 45vw;
    height: auto;
    //padding: 0 10%;
    padding: 0;
    color: $color-white;
    text-align: left;
    z-index: 100;

    &__topline {
        @include getFigmaStyle('Desktop/MD/Regular/Uppercase');
        text-align: left;
        margin-bottom: 16px;

    }

    &__headline {
        @include getFigmaStyle('Desktop/H3/Regular/Default');
    }
}

@include media-breakpoint-down(md) {
    .keyvisual {
        margin-top: pxToRem(88);
        //padding: 0 pxToRem(16);
        padding: 0;
    }

    .keyvisual__big {
        height: auto;
    }

    .claim__topline {
        text-align: center;
    }

    .claim {
        text-align: center;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: 0 10%;
        height: 100%;
        top: 0px;
        right: 0px;
        margin-top: pxToRem(64);
    }
}