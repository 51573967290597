.fewo-stickyinfobox {
    background-color: $color-primary-300;
    border-radius: 16px;
    padding: pxToRem(24);

    a:hover {
        text-decoration: none !important;
    }
}

.fewo-stickyinfobox__container {
    //margin-top: $spacing-xxl;
}

.fewo-stickyinfobox__inner {
    //padding: pxToRem(24) 0;
}

.fewo-stickyinfobox__topline {
    color: $color-primary-800;
    @include getFigmaStyle('Desktop/MD/Regular/Uppercase');
}

.fewo-stickyinfobox .fewo-stickyinfobox__hl {
    margin-top: pxToRem(16);
    margin-bottom: pxToRem(32);
    color: $color-primary-800;
}

.fewo-stickyinfobox__date {
    display: flex;
    flex-direction: column;
}

.fewo-sticky__input {
    color: $color-black;

    &:not(:first-child) {
        margin-top: pxToRem(12);
    }
}

.fewo-stickyinfobox .price__full {

}

@include media-breakpoint-up(lg) {

    .fewo_sticky_changefilter.button.button--tertiary--mixed.button--small:not(.button--link):hover {
        text-decoration: none;

        span {
            transform: none !important;
            color: $color-black;
        }
    }

    .fewo_sticky_changefilter.button.button--tertiary--mixed.button--small:not(.button--link)::after {
        //background-image: inline-svg('add', $color-secondary, transparent);
    }

    .fewo_sticky_changefilter.button.button--tertiary--mixed.button--small:not(.button--link):hover::after, .fewo_sticky_changefilter.button.button--tertiary--mixed.button--small:not(.button--link):focus::after {
        //background-image: inline-svg('add', $color-secondary-hover, transparent);
        //border-color: $color-secondary-hover;
    }
}

.fewo-stickyinfobox__changelink-mobile {
    display: none;
}

.fewo-stickyinfobox__date .fewo-sticky__input--date {
    transition: all .3s ease;
    @include media-breakpoint-up(lg) {
        &:hover, &:focus {
            //border-color: $color-secondary-hover;
            //box-shadow: 0 0 0 3px rgba($color-secondary, .2);
        }
    }
}

.fewo-stickyinfobox__border {
    display: flex;
    flex-direction: column;
    padding-top: pxToRem(32);
    margin-top: pxToRem(32);
    border-top: 1px solid $color-white;
    transition: all .3s ease;

    @include media-breakpoint-up(lg) {
        &:hover, &:focus {
            //background-color: $color-secondary;
        }

        &:hover .price, &:focus .price {
            //color: $font-color-white;
        }
    }
}

.fewo-stickyinfobox__price {
    display: flex;

    .price {
        color: $color-primary-800;
        @include getFigmaStyle('Desktop/H3/Regular/Default');
    }

    .price__infotext {
        display: block;
        color: $color-black;
        @include getFigmaStyle('Desktop/MD/Regular/Default');
    }
}

.fewo-stickyinfobox__btn {
    display: flex;
    margin-top: pxToRem(24);

    a {
        flex: 1;

        &:hover, &:focus {
            text-decoration: none;
        }
    }
}

.fewo-stickyinfobox__changelink-mobile {
    display: none;
}

.fewo-stickyinfobox__error-hl {
    margin-top: 32px;
}

.fewo-stickyinfobox__error-message:not(:first-child) {
    //margin-top: $spacing-xxs;
}

@include media-breakpoint-down(lg) {
    .fewo-stickyinfobox__container {
        //z-index: 999;
        z-index: 450;
        margin-top: initial;
    }

    .fewo-stickyinfobox {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 800;
        width: 100%;
        min-height: 70px;
        //height: 70px;
        padding: 12px 20px;
        margin-bottom: initial;
        border-radius: initial;
        overflow: hidden;
    }

    .fewo-stickyinfobox__border {
        flex-direction: row;
        align-items: center;
        padding: initial;
        margin-top: initial;
        border: none;
    }

    .fewo-stickyinfobox__date, .fewo-stickyinfobox__date, .fewo-stickyinfobox__hl, .fewo-stickyinfobox__topline, .price__full, .price__infotext, .fewo-stickyinfobox__inner, .fewo-stickyinfobox__price .price__infotext {
        display: none;
    }

    .fewo-stickyinfobox__btn {
        flex: 1;
        min-width: 160px;
        max-width: 260px;
        margin-top: initial;
        margin-left: auto;

        a.button.button--large:not(.button--link) {
            padding: initial;
        }
    }

    .fewo-stickyinfobox__changelink-mobile {
        display: flex;
        flex-direction: column;
        font-size: pxToRem(15);
        text-decoration: underline;
        text-align: left;

        .fewo-stickyinfobox__changelink-mobile--text {
            display: inline-block;
            font-size: pxToRem(13);
            text-decoration: underline;
            text-align: left;
        }
    }

    .fewo-stickyinfobox__price {
        flex-direction: column;
        margin-right: pxToRem(24);
    }

    .fewo-stickyinfobox__price .price {
        font-size: pxToRem(24);
    }
}

@include media-breakpoint-down(sm) {
    .fewo-stickyinfobox__btn {
        max-width: initial;
    }
}