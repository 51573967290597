$textarea-size: 14px !default;
$textarea-height: 34px !default;

.textarea {
    display: block;
    padding: $textarea-height * 0.2 $textarea-height * 0.3;
    min-height: $textarea-height;
    background: $color-white;
    font-size: $textarea-size;
    border: 1px solid $color-grey-200;
    border-radius: $border-radius;
    transition: 0.15s border;
}

.textarea.textarea--block {
    width: 100%;
}

.textarea:hover,
.textarea:focus {
    border: 1px solid $color-primary;
}

.textarea.textarea--small {
    font-size: $textarea-size * 0.875;
    padding: $textarea-height * 0.2 * 0.875 $textarea-height * 0.3 * 0.875;
    min-height: $textarea-height * 0.875;
}

.textarea.textarea--large {
    font-size: $textarea-size * 1.125;
    padding: $textarea-height * 0.2 * 1.125 $textarea-height * 0.3 * 1.125;
    min-height: $textarea-height * 1.125;
}

.textarea[disabled] {
    cursor: not-allowed;
    background: $color-grey-100;
}

.textarea[disabled]:hover,
.textarea[disabled]:focus {
    border: 1px solid $color-grey-200;
}

/******/

.formular {
    .textarea {
        padding: pxToRem(16);
        border-color: $color-white;
        border-radius: 24px;
        @include getFigmaStyle('Shadow/MD');
        transition: all .3s ease;

        @include media-breakpoint-up(md) {
            &:hover, &:focus {
                box-shadow: 0 0 0 3px $color-primary-400;
            }
        }
    }
}