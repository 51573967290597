@import "@sx-core/src/scss/element/nav.scss";


.burger {
    position: relative;
    top: initial;
    right: initial;
    margin-left: auto;
    z-index: 99999;

    .hamburger:hover {
        opacity: 1;
    }

    .hamburger-inner {
        height: 2px;
        background-color: $color-primary-800;

        &::before, &::after {
            height: 2px;
            background-color: $color-primary-800;
        }

        &::before {
            top: -8px;
        }

        &::after {
            bottom: -8px;
        }
    }
}

.header__navigation {
    display: flex;
    align-items: center;
    flex: 1;
    height: 100%;
    width: auto;

    a {
        white-space: nowrap;
    }

    ul {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        //width: 100%;
        width: auto;
        margin: initial;
    }

    ul > li.level-0 {
        margin: 0 pxToRem(18);
        padding: initial;
        transition: all .3s ease;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            display: inline-block;
            width: 0;
            height: 2px;
            background-color: $color-primary-400;
            transition: all .3s ease;
        }

        @include media-breakpoint-up(md) {
            &:hover, &:focus {
                transform: translateY(-2px);

                &::after {
                    width: 100%;
                }
            }
            &.active {
                &:after {
                    width: 100%;
                }
            }
        }
    }

    ul > li:hover ul {
        pointer-events: auto;
    }

    ul > li a {
        position: relative;
        display: flex;
        align-items: center;
        padding: pxToRem(6) 0;
        color: $color-black;
        @include getFigmaStyle('Desktop/MD/Regular/Default');
        transition: all .3s ease;
    }

    ul ul {
        left: 50%;
        transform: translateX(-50%);
    }

    li.level-0 > ul {
        background-color: $color-secondary-800;
    }

    li.level-1:not(.has-children) {
        display: flex;
        align-items: center;
        padding: pxToRem(5) pxToRem(32);
    }
}

.header__booking-btn {
    white-space: nowrap;
}

.header__contact {
    display: none;
    color: $color-primary-1200;
    @include getFigmaStyle('Mobile / SM / Regular / Default');

    a {
        color: $color-primary-1200;
    }

    &.contact .contact__email span {
        text-decoration: none;
    }

    span {
        display: inline-block;
        //margin-bottom: pxToRem(8);
        @include getFigmaStyle('Mobile / MD / Bold / Default');
    }
}

@include media-breakpoint-down(xl) {
    .js-nav-open {
        overflow: hidden;
    }

    .burger {
        display: inline-block;
    }

    .header__contact {
        display: block;
        color: #fff;
        a {
            color: #fff;
        }
    }

    .header__navigation {
        position: fixed;
        z-index: 550;
        top: 0;
        left: -100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        row-gap: pxToRem(36);
        width: 100%;
        min-height: 100vh;
        padding: pxToRem(100) pxToRem(20);
        background-color: $color-primary-800;
        -ms-overflow-style: scrollbar;
        overflow-style: scrollbar;
        transition: all .6s ease;
        color: #fff;

        ul {
            flex-direction: column;
            align-items: initial;
            height: auto;
            width: 100%;
        }

        ul > li.level-0 {
            display: flex;
            height: auto;
            padding: initial;

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                padding: pxToRem(20) 0;
                color: #fff;

                &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: 1px;
                    background-color: $color-primary-800;
                    opacity: 1;
                    transform: none;
                }
            }
        }
    }

    .header__language {
        ul {
            flex-direction: row;
            align-items: center;

            & > li .language__item--current::before {
                background-color: $color-primary-800;
            }

            & > li:not(:last-child)::after {
                background-color: $color-primary-800;
            }
        }
    }
    .button.header__booking-btn {
        display: none;
    }

    .header__additions {
        flex-direction: column;
        row-gap: pxToRem(20);
        width: 100%;
    }

    .js-nav-open {
        .header__logo {
            z-index: 600;
            path {
                transition: all .5s ease;
                fill: #fff;
            }
        }
        .header__navigation {

            left: 0;
        }
        .burger {
            .hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner::before, .hamburger.is-active .hamburger-inner::after {
                background-color: #fff;
            }

        }
        .header__booking-btn.button--primary  {
            display: block;
            background: #fff !important;
            color: #000;
            padding: 11px 12px 8px 12px;
            span {
                &:after {
                    background-image: inline-svg('arrow-m-right', $color-black);
                }
            }

        }
        .header__contact {
            display: none !important
        }
    }
}

@include media-breakpoint-down(xl) {
    .burger {
        margin-left: initial;
    }
}

@include media-breakpoint-down(lg) {
    .hamburger {
        padding: 23px 15px 15px 15px;
    }
}
@include media-breakpoint-down(md) {
    .burger {
        margin-left: auto;
    }

    .js-nav-scrolled {
        .burger {
            margin-left: initial;
        }

        .header__booking-btn {
            margin-left: auto;
        }
    }
}