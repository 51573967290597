.image {
    position: relative;
    width: 100%;
    height: auto;
}

.image.image--background {
    position: absolute;
}

.image img,
.video img {
    z-index: 1;
    width: 100%;
    object-fit: cover;
    image-rendering: -webkit-optimize-contrast;
}

.image.lazyloaded img,
.video.lazyloaded img {
    visibility: visible !important;
}

.image picture,
.video picture {
    opacity: 0;
}

.image.lazyloaded picture,
.video.lazyloaded picture {
    opacity: 1;
    transition-property: opacity;
    transition-duration: 750ms;
    transition-timing-function: ease-in;
    transition-delay: 100ms;
}

.image:not([data-ratio]) picture,
.video:not([data-ratio]) picture {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}