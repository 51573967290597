.teaser-zb {
    position: relative;
    z-index: 1;
    margin-top: $modulspacing-desktop-m;
    margin-bottom: $modulspacing-desktop-m;
}

.teaser-item-zb {
    position: relative;

    &__body {
        position: absolute;
        left: 50%;
        bottom: 50%;
        transform: translate(-50%, 50%);
        z-index: 10;
    }

    &__body-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__headline.headline {
        color: $color-white;
        text-align: center;
    }

    &__teasertext {
        color: $color-white;
    }

    &__readmore {
        margin-top: pxToRem(32);
    }
    .image {
        position: relative;
         &:before {
             content: "";
             display: block;
             background: rgba(0, 0, 0, 0.1);
             position: absolute;
             left: 0;
             top: 0;
             bottom: 0;
             right: 0;
             z-index: 1;
        }
    }
}

@include media-breakpoint-down(md) {
    .teaser-zb {
        position: relative;
        z-index: 1;
        margin-top: $modulspacing-mobile-m;
        margin-bottom: $modulspacing-mobile-m;
        .readmore, .readmore__inner {
            width: 100%;
        }
    }
    .teaser-item-zb {
        position: relative;

        &__body {

            left: 20px;
            width: calc(100% - 40px);
            z-index: 10;
            transform: translate(0,50%);
        }
        .teaser-item-zb__teasertext {
            display: none;
        }
    }
}