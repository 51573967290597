
.video {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.video__image {
    z-index: 3;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition-property: opacity;
    transition-duration: 500ms;
    transition-timing-function: ease-in;
    transition-delay: 100ms;
}

.video__image img,
.video__image picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video.ready .video__image {
    opacity: 0;
}

.video__wrap {
    opacity: 0;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    transform: translate(-50%, -50%);
    transition-property: opacity;
    transition-duration: 500ms;
    transition-timing-function: ease-in;
    transition-delay: 50ms;
}

.video.ready .video__wrap {
    opacity: 1;
}

.video.portrait .video__wrap {
    padding-right: 267%;
    height: 150%;
}

.video.landscape .video__wrap {
    padding-bottom: 57%;
    width: 100%;
}

.video__wrap video,
.video__wrap iframe {
    pointer-events: none;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.keyvisual .video__unmute {
    z-index: 500;
    position: absolute;
    bottom: 25px;
    left: calc(50% - 25px);
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: center center;
    background-color: rgba($color-white, 0.6);
    border-radius: 500px;
    background-image: inline-svg('volume', $color-black);
}

.keyvisual .video__unmute.active {
    background-color: rgba($color-white, 0.9);
    background-image: inline-svg('volume-slash', $color-primary);
}

