.feedbacks {
    display: none;
}

.feedback__avg {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: $spacing-m pxToRem(24);
    background-color: $color-primary-300;
    border-radius: 16px;
    border-bottom: none;
    transition: all .3s ease;
    cursor: pointer;

    &.open {
        border-radius: 16px 16px 0 0;

        &::after {
            background-image: inline-svg('minus-circle', $color-white, transparent);
        }
    }

    .star {
        margin-right: $spacing-xxs;
    }

    span {
        margin-right: $spacing-xxs;
        font-size: pxToRem(20);
        font-weight: 600;
    }

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 24px;
        transform: translateY(-50%);
        width: 32px;
        height: 32px;
        background-color: $color-primary;
        background-image: inline-svg('add-circle', $color-white, transparent);
        background-position: center;
        background-size: 24px 24px;
        background-repeat: no-repeat;
        border-radius: 50%;
        transition: all .3s ease;
    }
}

.feedback__item:last-child .feedback-item__comment {
    border-radius: 0 0 16px 16px;
}

.feedback-item {
    &__content {
        padding: pxToRem(24);
    }

    &__rating {
        margin-bottom: pxToRem(12);
    }

    &__data {
        margin-bottom: pxToRem(12);
    }
}

.feedback-content {
    &__rating {
        display: flex;
        align-items: center;
        margin-bottom: pxToRem(12);

        span {
            margin-left: $spacing-xxs;
            font-size: pxToRem(17);
            font-weight: 600;
        }
    }

    &__data {
        margin-bottom: pxToRem(12);
        font-size: pxToRem(15);
    }

    &__text {
        font-size: pxToRem(17);
    }
}

.feedback-item__comment {
    padding: pxToRem(24);
    background-color: $color-tertiary-light;

    &-title {
        position: relative;
        display: flex;
        align-items: center;
        font-weight: 600;

        &::after {
            content: '';
            display: inline-block;
            width: 32px;
            height: 32px;
            margin-left: $spacing-xxs;
            background-color: $color-primary;
            background-image: inline-svg('arrow-circle', $color-white, transparent);
            background-position: center;
            background-size: 24px 24px;
            background-repeat: no-repeat;
            border-radius: 50%;
            transform: rotate(90deg);
            transition: all .3s ease;
        }

        &.open::after {
            transform: rotate(270deg);
        }
    }

    &-text {
        display: none;
        padding-bottom: pxToRem(12);
        margin-top: pxToRem(12);
    }
}

.feedback__more {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: pxToRem(19) pxToRem(24);
    background-color: $color-tertiary-light;
    border-radius: 0 0 16px 16px;
}

.star {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: inline-svg('star', transparent, transparent);
    background-position: center;
    background-size: 20px 20px;
    background-repeat: no-repeat;

    &:not(:first-child) {
        margin-left: pxToRem(4);
    }

    &--active {
        background-image: inline-svg('star', #F4C648);
    }
}

.feedback__more {
    .more__btn {
        position: relative;
        padding-right: pxToRem(40);

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 32px;
            height: 32px;
            background-color: $color-primary;
            background-image: inline-svg('plus-m', $color-white, transparent);
            background-position: center;
            background-size: 24px 24px;
            background-repeat: no-repeat;
            border-radius: 50%;
        }
    }
}

.feedback-item__content {
    background-color: $color-secondary-200;
}

@include media-breakpoint-down(sm) {
    .feedback__avg {
        padding: pxToRem(24) pxToRem(48) pxToRem(24) pxToRem(12);

        span {
            font-size: pxToRem(18);
        }

        &::after {
            right: 12px;
        }
    }

    .feedback-item__content, .feedback-item__comment {
        padding: pxToRem(20) pxToRem(12);

        &-title {
            font-size: pxToRem(15);
        }
    }

    .feedback-content {
        &__text {
            font-size: pxToRem(16);
        }
    }

    .feedback__more {
        justify-content: center;
    }
}