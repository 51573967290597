
.footer {
    width: 100%;
    height: 80px;
}

.footer > .container,
.footer > .container > .grid {
    height: 80px;
}

.footer__navigation ul {
    width: calc(100% + 16px);
    margin: 0 -8px;
}

.footer__navigation ul > li {
    display: inline-block;
    padding: 0 8px;
}

.footer__navigation ul > li a {
    font-size: 14px;
    color: $color-grey-600;
}

.footer__navigation ul > li a:hover {
    color: $color-grey-800;
}
