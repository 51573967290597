//.anfrage-widget {
//    //position: absolute;
//    position: relative;
//    z-index: 900;
//    width: 100%;
//    min-height: 80px;
//    color: $color-black;
//}
//
//.anfrage-widget__inner {
//    //position: absolute;
//    position: relative;
//    bottom: 0;
//    width: 100%;
//    transition: all .8s ease;
//}
//
//.anfrage-widget__body {
//    z-index: 10;
//    display: flex;
//    align-items: center;
//    width: 100%;
//    min-height: 80px;
//    padding: 12px $spacing-l;
//    background-color: $color-primary-400;
//    transition: all .5s ease;
//}
//
//.anfrage-widget__label {
//    margin-right: $spacing-xs;
//    font-size: pxToRem(17);
//    font-weight: 600;
//}
//
//.anfrage-widget__button.button:not(.button--link) {
//    margin-left: auto;
//}
//
//.filter__inline {
//    margin-left: $spacing-l;
//    @media(min-width: 1370px) {
//        margin-right: $spacing-l;
//    }
//}
//
//.anfrage-widget__gaeste, .anfrage-widget__daterange {
//    display: flex;
//    align-items: center;
//}
//
///* || Personen */
//
//.fewo-sticky__input--guests {
//    padding: 17px 56px;
//}
//
//.sx-person, .fewo-sticky__input--guests {
//    position: relative;
//    display: inline-flex;
//    align-items: center;
//    height: 56px;
//    //padding: 17px 56px;
//    border: 1px solid $color-secondary-800;
//    border-radius: 28px;
//    transition: all .3s ease;
//}
//
//.sx-person, .daterange, .fewo-sticky__input {
//    cursor: pointer;
//    white-space: nowrap;
//    transition: all .3s ease;
//
//    &::before {
//        content: '';
//        position: absolute;
//        top: 50%;
//        transform: translateY(-50%);
//        z-index: 10;
//        display: inline-block;
//        width: 32px;
//        height: 32px;
//        background-position: center center;
//        background-size: 24px 24px;
//        background-repeat: no-repeat;
//        pointer-events: none;
//    }
//
//    input {
//        background-image: inline-svg('arrow-form-down', $color-secondary-800, transparent);
//        background-position: center right 12px;
//        background-size: 32px 32px;
//        background-repeat: no-repeat;
//        transition: all .3s ease;
//    }
//
//    //&::after {
//    //    right: 12px;
//    //    background-image: inline-svg('arrow-down', $color-secondary-800, transparent);
//    //    border: 1px solid $color-secondary-800;
//    //    border-radius: 50%;
//    //    transition: all .3s ease;
//    //}
//}
//
//.sx-person, .fewo-sticky__input {
//    @include media-breakpoint-up(lg) {
//        &:hover, &:focus {
//            box-shadow: 0 0 0 3px rgba($color-secondary-800, .2);
//
//            //&::after {
//            //    border-color: $color-primary;
//            //    background-image: inline-svg('arrow-down', $color-secondary-1000, transparent);
//            //}
//
//            .sx-person__display {
//                background-image: inline-svg('arrow-form-down', $color-secondary-1000, transparent);
//            }
//        }
//    }
//}
//
//.fewo-sticky__input {
//    @include media-breakpoint-up(lg) {
//        &:hover, &:focus {
//            box-shadow: 0 0 0 3px rgba($color-secondary-800, .2);
//
//            &::after {
//                border-color: $color-primary;
//                background-image: inline-svg('arrow-down', $color-secondary-1000, transparent);
//            }
//        }
//    }
//}
//
//.sx-person, .fewo-sticky__input--guests {
//    &::before {
//        left: 12px;
//        background-image: inline-svg('user', $color-secondary-800, transparent);
//        transition: all .3s ease;
//
//    }
//
//    @include media-breakpoint-up(lg) {
//        &:hover, &:focus {
//            //border-color: $color-secondary-1000;
//            &::before {
//                background-image: inline-svg('user', $color-secondary-1000, transparent);
//            }
//        }
//    }
//
//    //@include media-breakpoint-up(lg) {
//    //    &:hover, &:focus {
//    //        border-color: $color-secondary-1000;
//    //
//    //        &::before {
//    //            background-image: inline-svg('user', $color-secondary-1000, transparent);
//    //        }
//    //
//    //        &::after {
//    //            background-image: inline-svg('arrow-down', $color-secondary-1000, transparent);
//    //            border-color: $color-secondary-1000;
//    //        }
//    //    }
//    //}
//}
//
///* || Daterange */
//
//.anfrage-widget__daterange {
//    margin-left: $spacing-l;
//
//    @include media-breakpoint-up(lg) {
//        &:hover, &:focus {
//            &::before {
//                background-image: inline-svg('calendar-edit', $color-secondary-1000, transparent);
//            }
//
//            &::after {
//                border-color: $color-primary;
//                background-image: inline-svg('arrow-down', $color-secondary-1000, transparent);
//            }
//        }
//    }
//
//    input {
//        transition: all .3s ease;
//
//        @include media-breakpoint-up(lg) {
//            &:hover, &:focus {
//                border: 1px solid $color-secondary-800;
//                box-shadow: 0 0 0 3px rgba($color-secondary-800, .2);
//            }
//        }
//    }
//
//
//}
//
////.anfrage-widget__daterange .daterange {
////    &::before, &::after {
////        transition: all .3s ease;
////    }
////
////    @include media-breakpoint-up(lg) {
////        &:hover, &:focus {
////            &:before {
////                background-image: inline-svg('calendar-edit', $color-secondary-1000, transparent);
////            }
////
////            &::after {
////                background-image: inline-svg('arrow-down', $color-secondary-1000, transparent);
////                border: 1px solid $color-secondary-1000;
////            }
////        }
////    }
////}
//
//.anfrage-widget__daterange input, .input.sx-daterange, .fewo-sticky__input--date {
//    position: relative;
//    display: flex;
//    align-items: center;
//    height: 56px;
//    padding: 17px 56px;
//    background-color: transparent;
//    border: 1px solid $color-secondary-800;
//    border-radius: 28px;
//    transition: all .3s ease;
//
//    &::placeholder {
//        color: $color-black;
//    }
//
//    @include media-breakpoint-up(md) {
//        min-width: 300px;
//    }
//}
//
//.daterange, .fewo-sticky__input--date {
//    position: relative;
//
//    &::before {
//        left: 12px;
//        background-image: inline-svg('calendar-edit', $color-secondary-800, transparent);
//        transition: all .3s ease;
//    }
//
//    @include media-breakpoint-up(lg) {
//        &:hover, &:focus {
//            &::before {
//                background-image: inline-svg('calendar-edit', $color-secondary-1000, transparent);
//            }
//
//            //&::after {
//            //    border-color: $color-secondary-1000;
//            //    background-image: inline-svg('arrow-down', $color-secondary-1000, transparent);
//            //}
//            input {
//                background-image: inline-svg('arrow-form-down', $color-secondary-1000, transparent);
//            }
//        }
//    }
//}
//
///* || Filterbutton */
//
//.btn--plus {
//    display: flex;
//    align-items: center;
//    color: $color-black;
//    white-space: nowrap;
//
//    &::after {
//        content: '';
//        position: relative;
//        width: 32px;
//        height: 32px;
//        margin-left: $spacing-xxs;
//        background-color: $color-primary;
//        background-image: inline-svg('add-circle', $color-white, transparent);
//        background-position: center center;
//        background-size: 24px 24px;
//        background-repeat: no-repeat;
//        border-radius: 50%;
//    }
//}
//
///* || Filter PopUp */
//
//.fewosfilter-popup {
//    position: fixed;
//    top: 150px;
//    left: 50%;
//    transform: translateX(-50%);
//    z-index: 992;
//    width: 70%;
//    max-width: 800px;
//    height: calc(100vh - 200px);
//    overflow: hidden;
//    pointer-events: none;
//    opacity: 0;
//    transition: all .8s ease;
//}
//
//.fewosfilter-bglayer {
//    position: fixed;
//    top: 0;
//    bottom: 0;
//    left: 0;
//    right: 0;
//    z-index: 990;
//    display: block;
//    width: 100%;
//    background: rgba(0, 0, 0, .3);
//    opacity: 0;
//    pointer-events: none;
//    transition: all .8s ease;
//}
//
//.fewosfilter-popup__inner::before {
//    content: '';
//    position: absolute;
//    top: 0;
//    bottom: 0;
//    left: 0;
//    right: 0;
//    z-index: -1;
//    display: block;
//    width: 100%;
//    height: 100%;
//    //background: rgba(#1E1A15, .714);
//    background: #545963;
//    //-webkit-backdrop-filter: blur(9px);
//    //backdrop-filter: blur(9px);
//}
//
//.voffice-form {
//    position: relative;
//}
//
//.voffice-form__header {
//    position: sticky;
//    top: 0;
//    left: 0;
//    z-index: 100;
//    display: flex;
//    align-items: center;
//    width: 100%;
//    padding: 20px 16px;
//    //background: rgba($color-black, .2);
//    background: $color-secondary;
//    //backdrop-filter: blur(9px);
//
//    .voffice-form__title h2 {
//        font-size: pxToRem(20);
//        font-weight: 600;
//        color: $color-black;
//    }
//}
//
//.voffice-form__close {
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    width: 46px;
//    height: 46px;
//    margin-left: auto;
//    border: 1px solid $color-primary;
//    border-radius: 50%;
//    background-color: $color-primary;
//    background-image: inline-svg('plus', $color-white);
//    background-repeat: no-repeat;
//    background-position: center center;
//    background-size: 20px 20px;
//    transform: rotate(45deg);
//    cursor: pointer;
//    transition: all .3s ease;
//
//    @include media-breakpoint-up(lg) {
//        &:hover, &:focus {
//            transform: rotate(135deg);
//        }
//    }
//}
//
//.js-popup-filter-is-open .fewosfilter-popup, .js-popup-filter-is-open .fewosfilter-bglayer {
//    pointer-events: auto;
//    opacity: 1;
//}
//
//.fewosfilter-popup__inner {
//    height: 100%;
//
//    .voffice-form__body {
//        height: calc(100vh - 360px);
//        min-height: inherit;
//        overflow: hidden;
//
//        .voffice-form__body-inner {
//            overflow-y: auto;
//            overflow-x: hidden;
//            padding: $spacing-s 12px 12px 0;
//            height: 100%;
//        }
//    }
//}
//
//.fewosfilter-popup {
//    .voffice-form__main.third {
//        margin: 0;
//    }
//}
//
//.voffice-form__main {
//    &.third {
//        padding: 12px 0;
//        //background: rgba($color-black, .2);
//        background: $color-secondary;
//        border-radius: 8px;
//    }
//
//    &:not(:last-child) {
//        margin-bottom: 25px;
//    }
//
//    h3 {
//        margin-bottom: 16px;
//        font-size: pxToRem(22);
//    }
//}
//
//.voffice-form__gaeste, .voffice-form__datetime, .voffice-form__select {
//    display: flex;
//    justify-content: space-between;
//    align-items: center;
//}
//
//.voffice-form__datetime .voffice-form__input {
//    flex: 1;
//
//    .input.sx-daterange {
//        width: 100%;
//        background-position: center right 12px;
//
//        @include media-breakpoint-up(lg) {
//            &:hover, &:focus {
//                border-color: $color-secondary-800;
//                box-shadow: 0 0 0 3px rgba($color-secondary-800, .2);
//
//                &::before {
//                    background-image: inline-svg('calendar-edit', $color-secondary-1000, transparent);
//                }
//
//                //&::after {
//                //    border-color: $color-primary;
//                //    background-image: inline-svg('arrow-down', $color-secondary-1000, transparent);
//                //}
//            }
//        }
//    }
//}
//
//.voffice-form__select {
//    justify-content: space-between;
//}
//
//.voffice-form {
//    color: $color-black;
//
//    .sx-person {
//        position: initial;
//        padding: initial;
//        border: none;
//
//        &::before, &::after {
//            content: none;
//        }
//
//        & > div {
//            white-space: nowrap;
//        }
//
//        //&:hover {
//        //    .sx-person__popover {
//        //        opacity: 1;
//        //        pointer-events: auto;
//        //    }
//        //}
//    }
//
//    .sx-person__display {
//        position: relative;
//        display: inline-flex;
//        align-items: center;
//        height: 56px;
//        padding: 17px 56px;
//        background-image: inline-svg('arrow-form-down', $color-secondary-800, transparent);
//        background-position: center right 12px;
//        background-size: 32px 32px;
//        background-repeat: no-repeat;
//        border: 1px solid $color-secondary-800;
//        border-radius: 28px;
//        cursor: pointer;
//
//        &::before {
//            content: '';
//            position: absolute;
//            top: 50%;
//            transform: translateY(-50%);
//            z-index: 10;
//            display: inline-block;
//            width: 32px;
//            height: 32px;
//            background-position: center center;
//            background-size: 24px 24px;
//            background-repeat: no-repeat;
//            pointer-events: none;
//            transition: all .3s ease;
//        }
//
//        &::before {
//            left: 12px;
//            background-image: inline-svg('user', $color-secondary-800, transparent);
//        }
//
//        //&::after {
//        //    right: 12px;
//        //    background-image: inline-svg('arrow-down', $color-secondary-800, transparent);
//        //    border: 1px solid $color-secondary-800;
//        //    border-radius: 50%;
//        //    transition: all .3s ease;
//        //}
//
//        @include media-breakpoint-up(lg) {
//            &:hover, &:focus {
//                box-shadow: 0 0 0 3px rgba($color-secondary-800, .2);
//            }
//        }
//    }
//
//    .sx-person__popover {
//        //position: absolute;
//        //top: 180px;
//        //left: 70px;
//        z-index: 20;
//        padding: 16px 32px 16px 12px;
//        //background-color: $color-primary-800;
//        //background-color: $color-secondary;
//        background-color: $color-white;
//
//        border-radius: 0.5rem;
//
//        box-shadow: 0 11px 15px -7px rgba($color-black, 20%), 0 24px 38px 3px rgba($color-black, 14%), 0 9px 46px 8px rgba($color-black, 12%) !important;
//
//        //backdrop-filter: blur(9px);
//        //pointer-events: none;
//        //opacity: 0;
//        transition: opacity .4s ease;
//
//        &:hover {
//            pointer-events: auto;
//        }
//
//        & > div {
//            display: flex;
//            margin-bottom: 10px;
//            white-space: nowrap;
//
//            span {
//                position: relative;
//                top: 2px;
//                left: 25px;
//                display: inline-flex;
//                justify-content: center;
//                width: 24px;
//            }
//
//            &:last-child {
//                margin-bottom: 0;
//            }
//        }
//
//        i {
//            display: inline-flex;
//            align-items: center;
//            justify-content: center;
//            width: 32px;
//            height: 32px;
//            margin-left: auto;
//            font-style: normal;
//            color: $color-secondary-800;
//            background: transparent;
//            border: 1px solid $color-secondary-800;
//            border-radius: 50%;
//            transform: translate(20px, -2px);
//            cursor: pointer;
//
//            &:last-child {
//                margin-left: 10px;
//            }
//        }
//
//        //&:before {
//        //    content: '';
//        //    position: absolute;
//        //    left: 20px;
//        //    top: -8px;
//        //    display: inline-block;
//        //    width: 0;
//        //    height: 0;
//        //    border-style: solid;
//        //    border-width: 0 9px 8px 9px;
//        //    border-color: transparent transparent $color-quaternary transparent;
//        //}
//
//    }
//
//    .sx-daterange {
//        color: $color-black;
//
//        &::placeholder {
//            color: $color-black;
//        }
//    }
//}
//
//.voffice-form__label {
//    margin-right: $spacing-xs;
//}
//
//.fewosfilter-popup, #quote, .filter2 {
//    .chosen-pseudo, .chosen-container .chosen-single, .chosen-container .chosen-choices,
//    .chosen-container.chosen-with-drop.chosen-container-active .chosen-single, .chosen-container.chosen-with-drop.chosen-container-active .chosen-choices {
//        position: relative;
//        height: 46px;
//        min-width: 160px;
//        min-height: 46px;
//        padding-left: 16px;
//        padding-right: 48px;
//        color: $color-black;
//        background-image: none;
//        background-color: transparent;
//        border: 1px solid $color-secondary-800;
//        border-radius: 23px;
//        cursor: pointer;
//        transition: all .3s ease;
//
//        &:after {
//            content: '';
//            position: absolute;
//            right: 8px;
//            z-index: 10;
//            display: flex;
//            align-items: center;
//            width: 32px;
//            height: 32px;
//            background-image: inline-svg('arrow-down', $color-secondary-800, transparent);
//            background-position: center center;
//            background-size: 24px 24px;
//            background-repeat: no-repeat;
//            border: 1px solid $color-secondary-800;
//            border-radius: 50%;
//            transition: all .3s ease;
//        }
//
//        @include media-breakpoint-up(lg) {
//            &:hover, &:focus {
//                &::after {
//                    border-color: $color-secondary-1000;
//                    background-image: inline-svg('arrow-down', $color-secondary-1000, transparent);
//                }
//            }
//        }
//    }
//
//    .chosen-container.chosen-container-single.chosen-container-single-nosearch.chosen-container-active.chosen-with-drop {
//        .chosen-single {
//            box-shadow: 0 0 0 3px rgba($color-secondary-800, .2);
//
//            &::after {
//                border-color: $color-secondary-1000;
//                background-image: inline-svg('arrow-down', $color-secondary-1000, transparent);
//            }
//        }
//    }
//
//    .chosen-container .chosen-choices:hover, .chosen-container.chosen-container-active .chosen-choices, .chosen-container.chosen-container-active .chosen-single, .chosen-container .chosen-single:hover, .chosen-container.chosen-with-drop .chosen-choices, .chosen-container.chosen-with-drop .chosen-single, .chosen-pseudo:focus, .chosen-pseudo:hover {
//        border-color: $color-secondary-800;
//        box-shadow: 0 0 0 3px rgba($color-secondary-800, .2);
//    }
//
//    .chosen-container .chosen-single.chosen-default > span, .chosen-container.chosen-with-drop.chosen-container-active .chosen-single > span {
//        color: $color-black;
//    }
//}
//
//.chosen-container .chosen-drop {
//    //background: $color-primary-800 !important;
//    //background: $color-secondary !important;
//    top: calc(100% + 4px) !important;
//    background: $color-white !important;
//
//    border-radius: 0.5rem !important;
//
//    box-shadow: 0 11px 15px -7px rgba($color-black, 20%), 0 24px 38px 3px rgba($color-black, 14%), 0 9px 46px 8px rgba($color-black, 12%) !important;
//
//    color: $font-color;
//    //backdrop-filter: blur(9px);
//    border: none !important;
//}
//
//.chosen-container .chosen-drop .chosen-results {
//    border-top: none !important;
//}
//
//.chosen-container .chosen-drop .chosen-results li.active-result:hover {
//    background: rgba($color-primary, .15) !important;
//}
//
//.chosen-container .chosen-drop .chosen-results li.result-selected {
//    background: rgba($color-primary, .3) !important;
//}
//
//.chosen-container .chosen-drop .chosen-results li:first-child {
//    border-radius: 0.5rem 0.5rem 0 0;
//}
//
//.chosen-container .chosen-drop .chosen-results li:last-child {
//    border-radius: 0 0 0.5rem 0.5rem;
//}
//
//.fewosfilter-popup__inner, .filter2, .anfrage-widget {
//    .checkbox>label input[type=checkbox] {
//        transition: all .3s ease;
//    }
//
//    input[type=checkbox] {
//        width: 24px !important;
//        height: 24px !important;
//        transform: translateY(-4px);
//        background-color: transparent !important;
//        background-image: none !important;
//        border-radius: 4px !important;
//        border-color: $color-secondary-800 !important;
//        cursor: pointer;
//        transition: outline .3s ease;
//
//        @include media-breakpoint-up(lg) {
//            &:hover, &:focus {
//                //border-color: $color-secondary-1000 !important;
//                box-shadow: 0 0 0 3px rgba($color-secondary-800, .2);
//            }
//        }
//    }
//
//    .checkbox > label input[type=checkbox]:checked {
//        &::after {
//            content: '';
//            position: absolute;
//            top: 50%;
//            left: 50%;
//            transform: translate(-50%, -50%);
//            display: inline-block;
//            width: 12px;
//            height: 12px;
//            background-color: $color-primary;
//            border-radius: 50%;
//        }
//    }
//
//    .checkbox {
//        span {
//            display: inline-block;
//            padding-right: 10px;
//            font-size: pxToRem(15);
//        }
//    }
//}
//
//.voffice-form__price {
//    position: relative;
//    display: flex;
//    align-items: center;
//
//    .voffice-form__input {
//        width: 100%;
//
//        input {
//            width: 100%;
//            height: 46px;
//            color: $color-black;
//            background: transparent;
//            border: 1px solid $color-secondary-800;
//            border-radius: 23px;
//            transition: all .3s ease;
//        }
//    }
//}
//
//.noUi-horizontal {
//    height: 6px !important;
//    border: none !important;
//    background: rgba(#B6B6B6, .2) !important;
//    outline: 0 !important;
//    margin: 16px 6px 0 6px !important;
//
//    .noUi-handle {
//        top: -10px !important;
//        width: 24px !important;
//        height: 24px !important;
//        border-radius: 50% !important;
//        background-color: $color-white !important;
//        border: 1px solid $color-primary !important;
//        cursor: pointer;
//
//        &:before, &:after {
//            display: none;
//        }
//    }
//}
//
//.noUi-target {
//    box-shadow: none !important;
//}
//
//.noUi-connect {
//    background: $color-primary !important;
//}
//
//.voffice-form__input {
//    .price__min, .price__max {
//        transition: all .3s ease;
//
//        @include media-breakpoint-up(lg) {
//            &:hover, &:focus {
//                box-shadow: 0 0 0 3px rgba($color-secondary-800, .2);
//            }
//        }
//    }
//}
//
//.voffice-form__footer {
//    //height: 82px;
//    padding: 12px;
//    position: sticky;
//    z-index: 10;
//    bottom: 0;
//    left: 0;
//    display: flex;
//    align-items: center;
//    width: 100%;
//    //background: rgba($color-black, .2);
//    background: $color-secondary;
//    //backdrop-filter: blur(9px);
//
//    .voffice-form__count {
//        //flex: 1 1 auto;
//        font-size: pxToRem(20);
//    }
//
//    .voffice-form__button {
//        //margin-left: 12px;
//
//
//        .button:not(.button--link) {
//            margin-right: initial;
//        }
//    }
//}
//
//.fewosfilter-popup--reduced {
//    height: auto;
//
//    .voffice-form__body {
//        height: auto;
//    }
//
//    .fewosfilter-popup__inner .voffice-form__body .voffice-form__body-inner {
//        padding-top: $spacing-m;
//        padding-bottom: 8rem;
//    }
//
//    .voffice-form__label {
//        min-width: 75px;
//    }
//
//    .voffice-form__gaeste {
//        justify-content: initial;
//    }
//}
//
//.anfrage-widget__gaeste .sx-person__popover:before, .toolbar .sx-person__popover:before {
//    border-color: transparent transparent $color-primary-800 transparent;
//}
//
//.sx-person__popover {
//    //background: $color-primary-800;
//    //background: $color-secondary;
//    background: $color-white;
//    border-radius: 0.5rem;
//
//    box-shadow: 0 11px 15px -7px rgba($color-black, 20%), 0 24px 38px 3px rgba($color-black, 14%), 0 9px 46px 8px rgba($color-black, 12%) !important;
//
//    & > div {
//        //color: $color-black;
//        color: $font-color;
//    }
//
//    i {
//        background: transparent;
//        color: $color-secondary-800;
//        transition: all .3s ease;
//
//        @include media-breakpoint-up(lg) {
//            &:hover, &:focus {
//                color: $color-secondary-1000;
//                border-color: $color-secondary-1000;
//            }
//        }
//    }
//
//    &::before {
//        content: '';
//        position: absolute;
//        bottom: -10px;
//        left: 0;
//        display: block;
//        width: 100%;
//        height: 10px;
//    }
//
//    &:after {
//        content: '';
//        position: absolute;
//        top: -10px;
//        left: 0;
//        display: block;
//        width: 100%;
//        height: 10px;
//    }
//
//    span {
//        position: relative;
//        display: inline-flex;
//        justify-content: center;
//        width: 24px;
//        left: 21px;
//        //color: $color-black;
//        color: $font-color;
//    }
//
//    @include media-breakpoint-up(lg) {
//        i:hover, i:focus {
//            border-color: $color-secondary-1000;
//        }
//    }
//}
//
//.sx-person__display {
//    position: relative;
//
//    //&::before {
//    //    content: '';
//    //    position: absolute;
//    //    top: 50%;
//    //    transform: translateY(-50%);
//    //    z-index: 10;
//    //    display: inline-block;
//    //    width: 32px;
//    //    height: 32px;
//    //    background-position: center center;
//    //    background-size: 24px 24px;
//    //    background-repeat: no-repeat;
//    //    pointer-events: none;
//    //    left: 12px;
//    //    background-image: inline-svg('user', $color-secondary-800, transparent);
//    //    transition: all .3s ease;
//    //}
//}
//
//.anfrage-widget__gaeste .sx-person__display {
//    position: relative;
//    padding: 17px 56px;
//    background-image: inline-svg('arrow-form-down', $color-secondary-800, transparent);
//    background-position: center right 12px;
//    background-size: 32px 32px;
//    background-repeat: no-repeat;
//}
//
///* || Search */
//
//.anfrage-widget__search {
//    position: relative;
//    display: flex;
//    align-items: center;
//    margin-left: $spacing-l;
//
//    &::before {
//        content: '';
//        display: inline-block;
//        width: 1px;
//        height: 32px;
//        margin-right: $spacing-l;
//        background-color: $color-secondary-800;
//    }
//
//    .frontendsearch {
//        width: auto;
//        margin: initial;
//
//        .button-group {
//            position: relative;
//        }
//
//        input {
//            height: 56px;
//            min-width: 200px;
//            background: transparent;
//            border: 1px solid $color-secondary-800;
//            border-radius: 28px;
//            padding: 0 $spacing-l 0 pxToRem(24);
//            transition: all .3s ease;
//        }
//
//        button {
//            position: absolute;
//            top: 50%;
//            right: 12px;
//            transform: translateY(-50%);
//            z-index: 10;
//            width: 32px;
//            height: 32px;
//            background-color: $color-primary;
//            background-image: inline-svg('search', $color-white, transparent);
//            background-position: center;
//            background-size: 20p x 20px;
//            background-repeat: no-repeat;
//            border-radius: 50%;
//
//            &:hover, &:focus {
//                cursor: pointer;
//            }
//        }
//    }
//
//    @include media-breakpoint-up(lg) {
//        &:hover .frontendsearch input, .frontendsearch input:focus {
//            box-shadow: 0 0 0 3px rgba($color-secondary-800, .2);
//        }
//    }
//}
//
///* || Media Queries */
//
//@include media-breakpoint-up(lg) {
//    .sx-person__display {
//        transition: all .3s ease;
//
//        &:hover, &:focus {
//            //border-color: $color-secondary-1000;
//
//            &::before {
//                background-image: inline-svg('user', $color-secondary-1000, transparent);
//            }
//
//            //&::after {
//            //    background-image: inline-svg('arrow-down', $color-secondary-1000, transparent);
//            //    border-color: $color-secondary-1000;
//            //}
//
//            background-image: inline-svg('arrow-form-down', $color-secondary-1000, transparent);
//        }
//    }
//
//    .sx-person__popover i {
//        transition: all .3s ease;
//
//        &:hover, &:focus {
//            color: $color-secondary-1000;
//            border-color: $color-secondary-1000 !important;
//        }
//    }
//}
//
//@include media-breakpoint-down(xl) {
//    .anfrage-widget__label {
//        display: none;
//    }
//
//    .fewosfilter-popup {
//        width: 90%;
//    }
//}
//
//@media (max-width: 1370px) {
//    .anfrage-widget__body {
//        flex-wrap: wrap;
//        padding: 12px 20px;
//    }
//
//    .anfrage-widget__daterange, .filter__inline {
//        margin-left: 20px;
//    }
//}
//
//@media(max-width: 1040px) {
//    .anfrage-widget__body {
//        flex-wrap: wrap;
//    }
//
//    .anfrage-widget__button {
//        flex: 1 1 100%;
//        margin-top: 12px;
//    }
//}
//
//@include media-breakpoint-down(md) {
//    .anfrage-widget {
//        padding-bottom: pxToRem(40);
//    }
//    .anfrage-widget__daterange {
//        flex: 1 1;
//
//        .daterange {
//            display: flex;
//            width: 100%;
//        }
//
//        .sx-daterange {
//            flex: 1;
//            width: 100%;
//        }
//    }
//
//    .filter__inline {
//        position: absolute;
//        bottom: 0;
//        left: 0;
//        transform: translateY(100%);
//        display: flex;
//        justify-content: center;
//        width: 100%;
//        padding: 4px 0;
//        margin-left: initial;
//        background-color: $color-primary-800;
//    }
//
//    .fewosfilter-popup {
//        width: calc(100vw - 24px);
//    }
//}
//
//@include media-breakpoint-down(sm) {
//    .fewosfilter-popup {
//        top: 8px;
//        height: calc((var(--vh, 1vh) * 100) - 16px);
//    }
//
//    .fewosfilter-popup:not(.fewosfilter-popup--reduced) .fewosfilter-popup__inner .voffice-form__body {
//        height: calc(100vh - 180px);
//    }
//
//    .anfrage-widget__gaeste .sx-person {
//        //padding-left: 15px;
//
//        &::before {
//            content: none;
//        }
//    }
//
//    .anfrage-widget__daterange .sx-daterange {
//        padding-left: $spacing-l;
//    }
//
//    .voffice-form__footer .voffice-form__count {
//        margin-right: 12px;
//    }
//
//    .voffice-form__footer .voffice-form__button {
//        //margin-left: 12px;
//        display: flex;
//        flex: 1 1 auto;
//    }
//
//    .voffice-form__button .button.button--primary.button--large {
//        flex: 1;
//    }
//
//    .anfrage-widget__gaeste .sx-person__display {
//        background-position: center right 6px;
//        &::before {
//            content: '';
//            position: absolute;
//            top: 50%;
//            transform: translateY(-50%);
//            z-index: 10;
//            display: inline-block;
//            width: 32px;
//            height: 32px;
//            background-position: 50%;
//            background-size: 24px 24px;
//            background-repeat: no-repeat;
//            pointer-events: none;
//            left: 12px;
//            background-image: inline-svg('user', $color-secondary-800, transparent);
//        }
//    }
//
//    .voffice-form__input select {
//        color: $color-black;
//    }
//
//    .sx-person {
//        height: 46px;
//        border-radius: 23px;
//        &::after {
//            right: 6px;
//        }
//    }
//
//    .anfrage-widget__gaeste .sx-person__display {
//        padding: 12px 48px 12px 15px;
//        &::before {
//            content: none;
//        }
//    }
//
//    .anfrage-widget__daterange .input.sx-daterange {
//        height: 46px;
//        padding: 12px 48px 12px 15px;
//        border-radius: 23px;
//        background-position: center right 6px;
//    }
//
//    .anfrage-widget__daterange .daterange::before {
//        display: none;
//    }
//
//    .anfrage-widget__daterange .daterange::after {
//        right: 6px;
//    }
//
//    .anfrage-widget__button {
//        margin-top: $spacing-xxs;
//    }
//
//    .anfrage-widget__button.button.button--large:not(.button--link) {
//        height: 46px;
//        padding: 14px 48px;
//        border-radius: 23px;
//    }
//
//    .anfrage-widget__gaeste {
//        margin-right: 6px;
//    }
//}
//
//@media(max-width: 400px) {
//    .voffice-form__datetime, .voffice-form__gaeste, .voffice-form__select {
//        flex-direction: column;
//        align-items: flex-start;
//    }
//
//    .voffice-form .sx-person__popover {
//        top: 212px;
//        left: 12px;
//    }
//
//    .voffice-form__button .button.button--primary.button--large {
//        padding: 14px 24px;
//    }
//
//    .anfrage-widget__gaeste .sx-person__display {
//        //padding: 17px 48px;
//    }
//}
//
//// Suche
//.theme-default, .theme-bernstein {
//    @media(max-width: 1550px) {
//        .anfrage-widget__daterange {
//            margin-left: $spacing-xs;
//        }
//
//        .filter__inline {
//            margin: 0 $spacing-xs;
//        }
//
//        .anfrage-widget__search {
//            margin-left: $spacing-xs;
//
//            &::before {
//                margin-right: $spacing-xs;
//            }
//        }
//    }
//
//    @media (max-width: 1370px) {
//        .anfrage-widget__body {
//            flex-wrap: nowrap;
//        }
//
//        .anfrage-widget__label {
//            display: none;
//        }
//    }
//
//    @media(max-width: 1250px) {
//        .anfrage-widget__button.button.button--large:not(.button--link) {
//            padding: 19px 48px;
//            min-width: 200px;
//        }
//    }
//
//    @media(max-width: 1200px) and (min-width: 1100px) {
//        .anfrage-widget__daterange .daterange {
//            &::before {
//                display: none;
//            }
//
//            input {
//                padding-left: 24px;
//                min-width: 260px;
//                width: 260px;
//            }
//        }
//
//        .anfrage-widget__gaeste .sx-person {
//            &::before {
//                display: none;
//            }
//
//            .sx-person__display {
//                padding-left: 24px;
//            }
//        }
//    }
//
//    @media (max-width: 1100px) {
//        .anfrage-widget__search {
//            display: none;
//        }
//    }
//
//    @include media-breakpoint-down(md) {
//        .filter__inline {
//            margin-left: initial;
//        }
//    }
//
//    @media (max-width: 1040px) {
//        .anfrage-widget__body {
//            flex-wrap: wrap;
//        }
//    }
//
//    @include media-breakpoint-up(lg) {
//        .anfrage-widget__button.button.button--large:not(.button--link):hover span {
//            transform: translateX(-24px);
//        }
//    }
//}
//
//@include media-breakpoint-up(sm) {
//    .voffice-form__button {
//        margin-left: auto;
//    }
//}
//
///****/
//
//.fewosfilter-popup .voffice-form__input select {
//    appearance: none;
//    height: 56px;
//    padding-left: 16px;
//    padding-right: 48px;
//    color: $color-white;
//    background-color: transparent;
//    background-image: inline-svg('arrow-form-down', $color-secondary-800, transparent);
//    background-position: center right 12px;
//    background-size: 32px 32px;
//    background-repeat: no-repeat;
//    border: 1px solid $color-secondary-800;
//    border-radius: 28px;
//}

.anfrage-widget {
    position: absolute;
    bottom: pxToRem(96);
    left: 0;
    z-index: 200;
    width: 100%;

    &__inner {
        display: flex;
    }

    &__body {
        display: flex;
        justify-content: space-between;
        max-width: 880px;
        width: 80%;
        column-gap: pxToRem(24);
        padding: pxToRem(12) pxToRem(12) pxToRem(12) pxToRem(24);
        margin: 0 auto;
        background-color: $color-primary-300;
        border-radius: 320px;

    }

    &__gaeste, &__daterange, &__filter, &__button {
        display: flex;
        align-items: center;
        column-gap: pxToRem(16);
    }

    &__filter a {
        color: $color-black;
        text-decoration: underline;

        &::after {
            content: '';
            display: block;
            width: 36px;
            height: 36px;
            margin-top: -5px;
            margin-left: 12px;
            background-color: $color-primary-800;
            background-image: inline-svg('filter', $color-white, transparent);
            background-position: center;
            background-size: 12px;
            background-repeat: no-repeat;
            border: 2px solid $color-primary-800;
            border-radius: 50%;
            transition: all .3s ease;
        }

        @include media-breakpoint-up(md) {
            &:hover, &:focus {
                &::after {
                    background-color: $color-primary-600;
                    border-color: $color-primary-1000;
                }
            }
        }
    }

    .sx-person__display {
        height: 48px;
        padding: pxToRem(14) pxToRem(48) pxToRem(10) pxToRem(48);
    }
}

.daterange {
    .sx-daterange {
        height: 48px;
        padding: pxToRem(14) pxToRem(48) pxToRem(10) pxToRem(48);
        background-color: $color-white;
        background-image: inline-svg('calendar', $color-primary-800, transparent), inline-svg('angle-solid', $color-primary-800);
        background-position: left 12px center, right 12px center;
        background-size: 24px 24px;
        background-repeat: no-repeat;
        border-radius: 320px;
        @include getFigmaStyle('Shadow/MD');
        min-width: 285px;
        transition: box-shadow .3s ease;
    }
}

.sx-person__display, .sx-daterange {
    transition: all .3s ease;
    @include media-breakpoint-up(md) {
        &:hover, &:focus {
            box-shadow: 0 0 0 3px $color-primary-400;
        }
    }
}

.sx-person__display {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 56px;
    padding: 17px 56px;
    background-color: $color-white;
    background-image: inline-svg('user', $color-primary-800, transparent), inline-svg('angle-solid', $color-primary-800);
    background-position: left 12px center, right 12px center;
    background-size: 24px 24px;
    background-repeat: no-repeat;
    border: 1px solid $color-white;
    border-radius: 28px;
    cursor: pointer;
    @include getFigmaStyle('Shadow/MD');
}

.sx-person__popover {
    z-index: 20;
    padding: pxToRem(16) pxToRem(32) pxToRem(16) pxToRem(12);
    background-color: $color-secondary-200;
    border-radius: 0.5rem;
    @include getFigmaStyle('Shadow/MD');
    transition: opacity .4s ease;

    &:hover {
        pointer-events: auto;
    }

    & > div {
        display: flex;
        margin-bottom: 10px;
        white-space: nowrap;

        span {
            position: relative;
            top: 2px;
            left: 25px;
            display: inline-flex;
            justify-content: center;
            width: 24px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    i {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        margin-left: auto;
        font-style: normal;
        color: $color-white;
        background-color: $color-primary-800;
        border: 2px solid $color-primary-800;
        border-radius: 50%;
        transform: translate(20px, -2px);
        cursor: pointer;
        transition: background-color .3s ease;
        padding-top: 4px;

        &:hover, &:focus {
            background-color: $color-primary-600;
            border-color: $color-primary-1000;
        }

        &:last-child {
            margin-left: 10px;
        }
    }
}

ins {
    text-decoration: none;
}

.sx-daterange {
    color: $color-black;

    &::placeholder {
        color: $color-black;
    }
}
.anfrage-widget__body {
    visibility: visible !important
}

@include media-breakpoint-down(xl) {
    .anfrage-widget {
        bottom: -2.2rem;
    }
    .anfrage-widget__body {
        border-radius: pxToRem(16);
        //max-width: pxToRem(335);
        flex-wrap: wrap;
        padding: pxToRem(12);
        gap: pxToRem(8);
        .daterange {
            display: flex;
            flex: 1;
            .sx-daterange {
                min-width: inherit;
            }
        }
    }
    .anfrage-widget__label {
        display: none;
    }
    .anfrage-widget__gaeste {
        width: 38%;
    }
    .anfrage-widget__daterange {
        width: calc(62% - 8px);
    }
    .anfrage-widget .sx-person__display {
        padding: 3px 40px 0 20px;
        background-image: inline-svg('angle-solid', $color-primary-800);
        background-position: calc(100% - 10px) 12px;
        white-space: nowrap;
        width: 100%;
    }
    .sx-person {
        width: 100%;
    }

    .daterange .sx-daterange {
        background-image: inline-svg('angle-solid', $color-primary-800);
        background-position: calc(100% - 10px) 12px;
        padding: 3px 30px 0 20px;
        width: 100%;

    }
    .anfrage-widget__button {
        width: 100%;
    }
    .anfrage-widget__filter {
        order: +1;
        margin-top: 4px;
        a:after {
            width: 22px;
            height: 22px;
        }
    }
}

@include media-breakpoint-down(md) {
    .anfrage-widget__body {
        width: 100%;
    }
}

@media(max-width: 450px) {
    .anfrage-widget__daterange, .anfrage-widget__gaeste {
        width: 100%;
    }
}
