.stoerer {
    position: absolute;
    left: 0;
    bottom: 50%;
    transform: translateY(50%);
    display: block;
    z-index: 100;
    max-width: pxToRem(400);
    height: pxToRem(240);
    background-color: $color-primary-800;
    border-top-right-radius: 320px;
    border-bottom-right-radius: 320px;

    &__inner {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        padding: pxToRem(8);
        padding-left: initial;
    }

    &__link {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: pxToRem(24);
        color: $color-white;
        text-align: center;
        border: 1px solid $color-white;
        border-left: initial;
        border-top-right-radius: 320px;
        border-bottom-right-radius: 320px;
    }

    &__headline {
        @include getFigmaStyle('Desktop/H6/Regular/Default');
    }

    &__content {
        margin-top: pxToRem(4);
        @include getFigmaStyle('Desktop/MD/Regular/Default');
    }

    &__subline {
        margin-top: pxToRem(12);
        @include getFigmaStyle('Desktop/XS/Medium/Uppercase');
    }
}

@include media-breakpoint-down(md) {
    .stoerer {
        bottom: pxToRem(257);
        max-width: pxToRem(320);
        height: pxToRem(170);
    }
}