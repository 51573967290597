@use "sass:math";

@function reverse($list) {
    $result: ();

    @if length($list) == 0 {
        @return $result;
    }

    @for $i from length($list) * -1 through -1 {
        $result: append($result, nth($list, abs($i)));
    }

    @return $result;
}

@function strip($number) {
    @return calc($number / ($number * 0 + 1));
}

@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return math.div($number, ($number * 0 + 1));
    }

    @return $number;
}

$rem-base: 16 !default;

@function pxToRem($value, $rem-base: $rem-base) {

    @if (unit($value) == rem) {
        @return $value;
    }

    $remValue: math.div(strip-unit($value), strip-unit($rem-base)) * 1rem;

    @return $remValue;

}

@function dynamic-color-text($bg) {
    @if (lightness($bg) > 50) {
        @return $color-neutral-dark;
    } @else {
        @return $color-neutral-light;
    }
}

@function text-contrast($n,$cd:$color-black,$cl:$color-white) {
    $color-brightness: round((red($n) * 299) + (green($n) * 587) + calc((blue($n) * 114) / 1000));
    $light-color: round((red(#ffffff) * 299) + (green(#ffffff) * 587) + calc((blue(#ffffff) * 114) / 1000));

    @if abs($color-brightness) < calc($light-color/1.7) {
        @return $cl;
    } @else {
        @return $cd;
    }
}

@mixin text-contrast($n,$cd,$cl) {
    $color-brightness: round((red($n) * 299) + (green($n) * 587) + calc((blue($n) * 114) / 1000));
    $light-color: round((red(#ffffff) * 299) + (green(#ffffff) * 587) + calc((blue(#ffffff) * 114) / 1000));

    @if abs($color-brightness) < calc($light-color/1.7) {
        color: $cl;
    } @else {
        color: $cd;
    }
}


@mixin dynamic-color($color) {
    @include dynamic-bgcolor($color, $color-neutral-dark, $color-neutral-light);
}

@mixin dynamic-bgcolor($bgc,$cd,$cl) {
    background-color: $bgc;
    @include text-contrast($bgc, $cd, $cl);
}


/// Replace `$search` with `$replace` in `$string`
/// @author Kitty Giraudel
/// @param {String} $string - Initial string
/// @param {String} $search - Substring to replace
/// @param {String} $replace ('') - New value
/// @return {String} - Updated string
@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

@function explode($string, $delimiter) {
    $result: ();
    @if $delimiter == "" {
        @for $i from 1 through str-length($string) {
            $result: append($result, str-slice($string, $i, $i));
        }
        @return $result;
    }
    $exploding: true;
    @while $exploding {
        $d-index: str-index($string, $delimiter);
        @if $d-index {
            @if $d-index > 1 {
                $result: append($result, str-slice($string, 1, $d-index - 1));
                $string: str-slice($string, $d-index + str-length($delimiter));
            } @else if $d-index == 1 {
                $string: str-slice($string, 1, $d-index + str-length($delimiter));
            } @else {
                $result: append($result, $string);
                $exploding: false;
            }
        } @else {
            $result: append($result, $string);
            $exploding: false;
        }
    }
    @return $result;
}


@mixin getFigmaStylePrefixed($figmaMixin : '') {

    $fm: str-replace($figmaMixin, '\/', '');
    $t: TextStyle#{$fm};
    $e: EffectStyle#{$fm};
    @debug $fm;

    @extend %#{$t} !optional;
    @extend %#{$e} !optional;

}

/**
 * include Figma Styles by Ag
 */

@mixin getFigmaStyle($figmaMixin : '') {

    $fm: $figmaMixin;

    //@debug $fm;

    $fm: str-replace($fm, 'XS', 'Xs');
    $fm: str-replace($fm, 'SM', 'Sm');
    $fm: str-replace($fm, 'MD', 'Md');
    $fm: str-replace($fm, 'LG', 'Lg');
    $fm: str-replace($fm, 'XL', 'Xl');
    $fm: str-replace($fm, 'XXL', 'Xxl');

    $fm: str-replace($fm, ' ', '');

    $desktop: str-replace($fm, 'Mobile\/', 'Desktop\/');
    $mobile: str-replace($fm, 'Desktop\/', 'Mobile\/');

    $mobile: str-replace($mobile, '\/', '');
    $desktop: str-replace($desktop, '\/', '');

    //@debug $mobile;
    //@debug $desktop;

    @extend %#{$mobile} !optional;
    @extend %#{$desktop} !optional;
}
