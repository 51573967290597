/**
 * Calendar and List
 */

.calendar__group {
    padding: 20px 0;
}

.calendar__group-title {
    text-align: center;
}

/**
 * Calendar
 */

.calendar__daterange {
    max-width: 360px;
    margin: 0 auto;
}

.calendar__daterange-from,
.calendar__daterange-to {
    position: relative;
}

.calendar__daterange-from > input,
.calendar__daterange-to > input,
.calendar__daterange-submit > button {
    width: 100%;
}

/**
 * Event
 */

.calendar__eventdate.is-today span {
    color: $color-primary;
}

.calendar__eventdate.is-current span {
    font-weight: bold;
}