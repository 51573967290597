.layout-error {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: $color-white;
}

.error {
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
    padding: 120px 30px;
    text-align: center;
}

.error h1 {
    color: $color-danger;
}

.error p {
    font-size: $base-font-size;
}