.downloads {
    margin-top: $spacing-m;
}

ul.downloads__items {
    display: flex;
    margin-top: $spacing-xs;

    li:not(:last-child) {
        margin-right: $spacing-m;
    }
}
