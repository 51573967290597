.toolbar {
    z-index: 981;
    display: flex;
    align-items: center;
    width: 100%;
    height: 56px;
    padding: 0 $spacing-l;
    background: rgba($color-white, .857);
    -webkit-backdrop-filter: blur(9px);
    backdrop-filter: blur(9px);
    opacity: 0;
    pointer-events: none;
    transition: all .5s ease;
    box-shadow: 0 1px 8px rgba(0,0,0,.16);

    &.uk-sticky-fixed {
        opacity: 1;
        pointer-events: auto;
    }
}

.toolbar__items {
    display: flex;
    width: 100%;
    white-space: nowrap;
}

.toolbar__left {
    display: flex;
    flex: 0;
    align-items: center;
}

.toolbar__center {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.toolbar__right {
    flex: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.toolbar__item {
    display: flex;
    align-items: center;

    li a {
        white-space: nowrap;
    }
}

.toolbar__label {
    font-size: pxToRem(17);
    font-weight: 400;
}

.toolbar__label--daterange, .toolbar__label--guests {
    display: none;
}

.toolbar__filter-btn {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    margin-right: $spacing-s;


    &::after {
        content: '';
        position: relative;
        display: inline-block;
        width: 32px;
        height: 32px;
        margin-left: $spacing-xxs;
        background-color: transparent;
        background-image: inline-svg('filter', $color-white, transparent);
        background-position: center center;
        background-size: 18px 18px;
        background-repeat: no-repeat;
        background-color: $color-primary-800;
        border: 1px solid $color-secondary;
        border-radius: 50%;
        transition: all .3s ease;
        border: none
    }

    @include media-breakpoint-up(lg) {
        &:hover::after, &:focus::after {
            background-image: inline-svg('filter', $color-secondary-hover, transparent);
            border-color: $color-secondary-hover;
        }
    }
}



.toolbar__guests, .toolbar__daterange, .toolbar__sort {
    display: flex;
    align-items: center;
    margin-right: $spacing-m;
}

.toolbar__sort .chosen-container.chosen-with-drop.chosen-container-active .chosen-single {
    background-image: none;
}

@include media-breakpoint-up(lg) {
    .toolbar__sort  .chosen-container {
        .chosen-single::after {
            transition: all .3s ease;
        }
        &:hover .chosen-single::after, &:focus .chosen-single::after {
            background-image: inline-svg('arrow-down', $color-secondary-hover, transparent);
            border-color: $color-secondary-hover;
        }
    }
}

.toolbar .sx-daterange {
    font-size: pxToRem(16);
    &::placeholder {
        font-size: pxToRem(16);
    }
}

.toolbar .chosen-container .chosen-single {
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    flex-wrap: nowrap;

    &::after {
        content: '';
        position: relative;
        display: inline-block;
        width: 32px;
        height: 32px;
        margin-left: $spacing-xxs;
        //background-image: inline-svg('arrow-down', $color-secondary, transparent);
        background-image: inline-svg('angle-solid', $color-primary-800);
        background-position: center center;
        background-size: 24px 24px;
        background-repeat: no-repeat;
        //border: 1px solid $color-secondary;
        border-radius: 50%;
    }

    & > span {
        order: initial;
        flex: initial;
    }
}

.toolbar .button--back.button.button--tertiary--mixed.button--small:not(.button--link) {
    span {
        color: $color-black;
    }
    &::after {
        content: none;
    }

    &::before {
        content: '';
        width: 32px;
        height: 32px;
        margin-right: $spacing-xxs;
        background-color: transparent;
        background-image: inline-svg('arrow-down', $color-secondary, transparent);
        background-position: center center;
        background-size: 24px 24px;
        background-repeat: no-repeat;
        border: 1px solid $color-secondary;
        border-radius: 50%;
        transition: all .3s ease;
        transform: rotate(90deg);
        opacity: 1;
    }

    @include media-breakpoint-up(lg) {
        &:hover::before, &:focus::before {
            background-image: inline-svg('arrow-down', $color-secondary-hover, transparent);
            border-color: $color-secondary-hover;
        }
    }
}

//-----------

.toolbar .sx-person {
    height: 32px;
    padding: 0 0px 0 0;
    //background-image: inline-svg('arrow-form-down', $color-secondary, transparent);


    &::before {
        left: 4px;
    }

    &::after {
        right: 4px;
        width: 24px;
        height: 24px;
    }

    @include media-breakpoint-up(md) {
        &:hover, &:focus {
            //background-image: inline-svg('arrow-form-down', $color-secondary-hover, transparent);
        }

        .sx-person__display {
            //background-image: none;
            background-image: inline-svg('angle-solid', $color-primary-800);
            background-position: center right 6px;
            background-size: 24px 24px;
            background-repeat: no-repeat;
        }
    }

    .sx-person__popover {
        top: 40px;
        //background-color: $color-background;
        //color: $color-white;

        background-color: $color-white;
        color: $font-color;

        border-radius: 0.5rem;

        box-shadow: 0 11px 15px -7px rgba($color-black, 20%), 0 24px 38px 3px rgba($color-black, 14%), 0 9px 46px 8px rgba($color-black, 12%) !important;
    }
}

.toolbar .daterange {
    &::before {
        left: 4px;
    }

    &::after {
        right: 4px;
        width: 24px;
        height: 24px;
    }
}

.toolbar .sx-daterange {
    transform: translateY(2px);
    height: 36px;
    min-width: 250px;
    padding: pxToRem(8) pxToRem(48) pxToRem(4) pxToRem(20);
    //background-image: inline-svg('arrow-form-down', $color-secondary, transparent);
    background-image: inline-svg('angle-solid', $color-primary-800);
    background-position: center right 6px;
    background-size: 24px 24px;
    background-repeat: no-repeat;

    &:hover, &:focus {
        border-color: $color-secondary !important;
        box-shadow: 0 0 0 3px rgba($color-secondary, .2);
    }
}

//------------

/* || Toolbar right */

.toolbar__map {
    &::after {
        content: '';
        position: relative;
        display: inline-block;
        width: 32px;
        height: 32px;
        margin-left: $spacing-xxs;
        background-image: inline-svg('map', $color-secondary, transparent);
        background-position: center center;
        background-size: 18px 18px;
        background-repeat: no-repeat;
        border: 1px solid $color-secondary;
        border-radius: 50%;
        transition: all .3s ease;
    }

    @include media-breakpoint-up(lg) {
        &:hover::after, &:focus::after {
            background-image: inline-svg('map', $color-secondary-hover, transparent);
            border-color: $color-secondary-hover;
        }
    }
}

//.js-toggle-map .toolbar__map::after {
//    background-image: inline-svg('map', $color-secondary-hover, transparent);
//    border-color: $color-secondary-hover;
//}

/* || Media Queries */

@include media-breakpoint-up(lg) {
    .toolbar__map:not(.filter--active)::after {
        background-image: inline-svg('filter', $color-secondary, transparent);
    }

    .toolbar__map:not(.filter--active) {
        &:hover, &:focus {
            &::after {
                background-image: inline-svg('filter', $color-secondary-hover, transparent);
                border-color: $color-secondary-hover;
            }
        }
    }

    .toolbar__map.filter--active::after {
        background-image: inline-svg('map', $color-secondary, transparent);
    }

    .toolbar__map.filter--active {
        &:hover, &:focus {
            &::after {
                background-image: inline-svg('map', $color-secondary-hover, transparent);
                border-color: $color-secondary-hover;
            }
        }
    }

    .toolbar__map.toolbar__item {
        display: none;
    }
}

@include media-breakpoint-up(sm) {
    .toolbar--map-show {
        display: none;
    }

    .toolbar--map-hide {
        display: block;
    }

    .js-toggle-map {
        .toolbar--map-show {
            display: block;
        }

        .toolbar--map-hide {
            display: none;
        }
    }
}

@include media-breakpoint-down(xl) {
    .toolbar--unit {
        overflow: auto hidden;
    }
}

@media(max-width: 1050px) {
    .toolbar__guests, .toolbar__daterange {
        display: none;
    }
}

@include media-breakpoint-down(md) {
    .js-nav-scrolled02 .toolbar {
        top: 71px !important;
    }

    .toolbar--map-hide, .toolbar--map-show {
        display: none !important;
    }
    .toolbar.uk-sticky-fixed {
        top: 88px !important;
    }
}

@include media-breakpoint-down(sm) {
    .toolbar {
        overflow: auto hidden;
    }
}

//******

.toolbar__sort select {
    appearance: none;
    min-height: 32px;
    padding-right: 32px;
    background-color: transparent;
    background-image: inline-svg('arrow-form-down', $color-secondary, transparent);
    background-position: right;
    background-size: 32px 32px;
    background-repeat: no-repeat;
    transition: all .3s ease;
}
//--------------------
.toolbar {
    height: 64px;
    background-color: $color-primary-400;

    &__items {
        display: flex;
        align-items: center;
        column-gap: pxToRem(24);
        height: 100%;
    }

    &__filter-btn, &__guests, &__daterange, &__sort {
        display: flex;
        align-items: center;
        column-gap: pxToRem(16);
    }

    

    &__guests {
        .sx-person__display {
            height: 36px;
            //padding: pxToRem(6) pxToRem(48);
            padding: pxToRem(8) pxToRem(48) pxToRem(4) pxToRem(20);
        }
    }
}


.filter__filtercount {
    position: absolute;
    top: -6px;
    right: -10px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    padding-top: 2px;
    font-size: pxToRem(11);
    font-weight: 400;
    line-height: 1.5;
    color: $color-white;
    background-color: $color-primary-600;
    border-radius: 50%;
}
.chosen-single span {
    //color: #fff; TODO: ANDI konflikt mit popup filter
}

@include media-breakpoint-down(md) {
    .toolbar__guests, .toolbar__daterange {
        display: none;
    }
}