$header-height: 80px !default;
$header-height-mobile: 100px !default;
$kvHeight: 100%; // auto for natural img size */

.keyvisual {

    min-height: 200px;
    position: relative;
    overflow: hidden;
    height: $kvHeight;

    .keyvisual__container {
        overflow: hidden !important;
        height: $kvHeight;
    }

    .keyvisual__wrapper, .keyvisual__wrapper > ul {
        height: $kvHeight;
    }

    .keyvisual__slide {
        flex: 0 0 auto;
    }

    .image {
        position: unset; //reset
        height: 100%;
    }

    .image img {
        position: absolute; //reset
        height: 100%;
    }
}

// Lazyload next Images
.container__keyvisual:not(.is-initialized) .splide__slide:not(:first-child) .image img {
    display: none;
}


@include media-breakpoint-up(md) {


    .keyvisual--default {
        // delete for natural img size
        height: calc(70vh - #{$header-height});
    }

    .keyvisual--big {
        // delete for natural img size
        height: calc(90vh - #{$header-height});
    }

    .keyvisual--empty {
        height: $header-height !important;
        min-height: $header-height !important;
    }

}

@include media-breakpoint-down(md) {

    .keyvisual--default {
        // delete for natural img size
        height: calc(70vh - #{$header-height-mobile});
    }

    .keyvisual--big {
        // delete for natural img size
        height: calc(90vh - #{$header-height-mobile});
    }

    .keyvisual--empty {
        height: $header-height-mobile !important;
        min-height: $header-height-mobile !important;
    }

}
