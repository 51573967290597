@import "@sx-core/src/scss/element/calendar.scss";

//.mbsc-ios.mbsc-calendar-button.mbsc-button, .mbsc-ios.mbsc-range-control-value.active, .mbsc-ios.mbsc-calendar-week-nr, .mbsc-ios.mbsc-calendar-today {
//    color: $color-primary-800;
//}
//
//.mbsc-ios.mbsc-selected .mbsc-calendar-cell-text {
//    border-color: $color-primary-800;
//    background: $color-primary-800;
//}
//
//.mbsc-ios.mbsc-hover .mbsc-calendar-cell-text {
//    background: $color-primary-800;
//    border-color: $color-primary-800;
//    color: $color-white;
//}
//
//.mbsc-ios.mbsc-range-day::after {
//    background-color: $color-grey-300;
//}
//
//.belegungskalender {
//    .mbsc-calendar-week-day {
//        border-radius: 50%;
//        width: 32px !important;
//        height: 32px !important;
//        margin: 3px;
//        display: flex;
//        align-items: center;
//        justify-content: center;
//        margin-bottom: 20px;
//        color: $font-color;
//        font-family: $font-family-primary;
//        font-size: pxToRem(15) !important;
//        font-weight: 700;
//        line-height: 0 !important;
//    }
//
//    .mbsc-calendar-cell.mbsc-disabled:not(.vacation-booked):not(.mbsc-selected) .mbsc-calendar-day-text {
//        color: #D5C9AF !important;
//    }
//
//    .mbsc-calendar-day-text {
//        border-radius: 50% !important;
//        width: 32px !important;
//        height: 32px !important;
//        display: flex !important;
//        align-items: center;
//        justify-content: center;
//        color: $font-color !important;
//        background-color: transparent !important;
//        opacity: 1 !important;
//        font-family: $font-family-primary;
//    }
//
//    .mbsc-calendar-cell.mbsc-disabled:not(.vacation-booked):not(.mbsc-selected) .mbsc-calendar-day-text {
//        color: #D5C9AF !important;
//    }
//
//    .vacation-booked {
//
//        .mbsc-calendar-day-text {
//            opacity: 1 !important;
//            background: #EAC1C1 !important;
//            border: none !important;
//            color: $color-black;
//        }
//    }
//
//    .vacation-check-in, .vacation-check-out {
//
//        .mbsc-calendar-day-text {
//            background: transparent;
//            position: relative;
//            border: none !important;
//            background: linear-gradient(90deg, rgba(252, 255, 255, 0) 50%, rgba(234, 193, 193, 1) 50%) !important;
//        }
//    }
//
//    .vacation-check-in {
//        .mbsc-calendar-day-text {
//            background: linear-gradient(90deg, rgba(234, 193, 193, 1) 50%, rgba(252, 255, 255, 0) 50%) !important;
//        }
//    }
//
//    .mbsc-range-day, .mbsc-range-day-start {
//        .mbsc-calendar-day-text {
//            background: $color-primary !important;
//            border: 1px solid $color-primary !important;
//            color: $color-white !important;
//
//            &:after {
//                background: $color-primary !important;
//            }
//        }
//    }
//
//    .mbsc-ios.mbsc-range-day::after {
//        display: none;
//    }
//
//    .mbsc-ios.mbsc-range-day::after, .mbsc-range-hover::before {
//        height: 36px !important;
//    }
//
//    .mbsc-ios.mbsc-range-day-end.mbsc-ltr::after, .mbsc-ios.mbsc-range-day-start.mbsc-rtl::after, .mbsc-ios.mbsc-range-hover-end.mbsc-ltr::before, .mbsc-ios.mbsc-range-hover-start.mbsc-rtl::before {
//        margin-right: -1.5em !important;
//    }
//
//    .mbsc-calendar-cell.mbsc-disabled:not(.vacation-booked):not(.mbsc-selected) .mbsc-calendar-day-text {
//        color: #d5c9af !important;
//    }
//}

#fewo__calendar .belegungskalender {
    padding: pxToRem(24) !important;
    background: $color-secondary-200 !important;
    border-radius: $border-radius !important;

    .mbsc-ios.mbsc-calendar-grid-view .mbsc-calendar-title {
        color: $color-black;
        @include getFigmaStyle('Desktop/MD/Bold/Underline');
    }

    .mbsc-calendar-week-days {
        margin-bottom: pxToRem(8);
    }

    .mbsc-calendar-week-day {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px !important;
        height: 32px !important;
        margin: pxToRem(3);
        color: $font-color;
        @include getFigmaStyle('Desktop/MD/Bold/Default');
    }

    .mbsc-calendar-day-text {
        display: flex !important;
        align-items: center;
        justify-content: center;
        width: 32px !important;
        height: 32px !important;
        color: $color-black;
        @include getFigmaStyle('Desktop/SM/Regular/Default');
        opacity: 1;
    }

    .mbsc-calendar-cell.mbsc-disabled:not(.vacation-booked):not(.mbsc-selected) .mbsc-calendar-day-text {
        color: $color-grey-500 !important;
    }

    .mbsc-ios.mbsc-selected .mbsc-calendar-cell-text {
        border-color: $color-primary-400;
        background: $color-primary-400;
    }

    .mbsc-ios.mbsc-hover .mbsc-calendar-cell-text {
        background: $color-primary-200;
        border-color: $color-primary-200;
    }

    .vacation-booked {
        .mbsc-calendar-day-text {
            opacity: 1 !important;
            background: $color-error-400 !important;
            border: none !important;
        }
    }

    .vacation-check-in, .vacation-check-out {
        .mbsc-calendar-day-text {
            background: transparent;
            position: relative;
            border: none !important;
            background: linear-gradient(135deg, $color-grey-100 50%, $color-error-400 50%) !important;
        }
    }

    .vacation-check-in {
        .mbsc-calendar-day-text {
            background: linear-gradient(135deg, $color-error-400 50%, $color-grey-100 50%) !important;
        }
    }

    .mbsc-range-day, .mbsc-range-day-start {
        .mbsc-calendar-day-text {
            background: $color-primary-400 !important;
            border: 1px solid $color-primary-400 !important;

            &:after {
                background: $color-primary-400 !important;
            }
        }
    }

    .mbsc-calendar-text.mbsc-calendar-text-placeholder {
        height: 0 !important;
    }

    .md-reset {
        display: flex;
        align-items: center;

        &::before {
            content: '';
            position: relative;
            display: inline-block;
            width: 36px;
            height: 36px;
            margin-right: pxToRem(8);
            background-color: $color-primary-800;
            background-image: inline-svg('close', $color-white, transparent);
            background-position: center;
            background-size: 24px;
            background-repeat: no-repeat;
            border: 2px solid $color-primary-800;
            border-radius: 50%;
            transition: background-color .3s ease;
        }

        &:hover, &:focus {
            &::before {
                background-color: $color-primary-600;
                border-color: $color-primary-1000;
            }
        }
    }

    .mbsc-button-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        &--nav {
            flex-wrap: nowrap;

            & > [mbsc-calendar-nav] {
                margin: 0 auto;
            }
        }
    }

    .mbsc-button-container--legend {
        justify-content: center;
        margin-top: pxToRem(36);
    }

    .mbsc-ios.mbsc-range-day::after {
        display: none;
    }

    .mbsc-ios.mbsc-datepicker-inline {
        border: none !important;
    }

    .mbsc-calendar-controls {
        flex-wrap: wrap;
    }

    .mbsc-calendar-month-title {
        text-align: center;
        color: $color-black;
        @include getFigmaStyle('Desktop/MD/Regular/Default');
    }

    .mbsc-calendar-grid {
        padding: 0 !important;
    }

    .mbsc-calendar-grid-row {
        //margin-left: -16px;
        //margin-right: -16px;
    }

    .mbsc-calendar-grid .mbsc-calendar-table {
        width: 90%;
    }

    .mbsc-ios.mbsc-button {
        padding: 0 !important;
    }

    /* || Legende */

    .md-book-rental-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1 1;
        margin-left: auto;
        overflow: hidden;
    }

    .md-book-rental-zone {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: pxToRem(4) pxToRem(28) 0 0;
        color: $color-black;
        @include getFigmaStyle('Desktop/XS/Regular/Default');
        white-space: nowrap;

        &.legend {
            white-space: inherit;
            text-align: center;
            padding: pxToRem(20) pxToRem(8);

            &:before {
                content: '7';
                display: flex;
                align-items: center;
                justify-content: center;
                color: $color-black;
                @include getFigmaStyle('Desktop/XS/Regular/Default');
                background: $color-secondary-800;
            }
        }

        &::before {
            content: '';
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-bottom: pxToRem(4);
            border-radius: 50%;
        }
    }

    .md-book-rental .mbsc-calendar-label-text {
        text-align: center;
    }

    .md-book-rental-booked::before {
        background-color: $color-error-400;
    }

    .md-book-rental-free::before {
        background-color: $color-grey-100;
    }

    .md-book-rental-check-in::before {
        background: linear-gradient(135deg, $color-error-400 50%, $color-grey-100 50%) !important;
    }

    .md-book-rental-check-out::before {
        background: linear-gradient(135deg, $color-grey-100 50%, $color-error-400 0) !important;
    }

    .md-book-rental-selection::before {
        background-color: $color-primary-400;
    }

    #booking-calendar {
        &.mbsc-ios.mbsc-datepicker-inline {
            background: transparent !important;
            border-color: transparent !important;
        }

        .mbsc-calendar, .mbsc-ios.mbsc-datepicker .mbsc-calendar-slide, .mbsc-ios.mbsc-datepicker .mbsc-calendar-cell,
        .mbsc-calendar-cell {
            background: transparent !important;
        }
    }

    .mbsc-flex, .mbsc-flex-col {
        display: flex !important;
        flex: 1 1 100% !important;
        margin-right: 0 !important;

        &.mbsc-calendar-grid-row {
            overflow: auto;
        }
    }

    .mbsc-ios.mbsc-datepicker .mbsc-calendar, .mbsc-ios.mbsc-datepicker .mbsc-calendar-slide, .mbsc-ios.mbsc-datepicker .mbsc-calendar-cell {
        background: transparent !important;
    }

    .mbsc-ios.mbsc-range-day::after, .mbsc-range-hover::before {
        height: 36px !important;
    }

    .mbsc-ios.mbsc-range-day-end.mbsc-ltr::after, .mbsc-ios.mbsc-range-day-start.mbsc-rtl::after, .mbsc-ios.mbsc-range-hover-end.mbsc-ltr::before, .mbsc-ios.mbsc-range-hover-start.mbsc-rtl::before {
        margin-right: -1.5em !important;
    }

    .mbsc-ios.mbsc-calendar-grid .mbsc-calendar-grid-item .mbsc-calendar-week-days {
        justify-content: space-around;
    }

    .mbsc-ios.mbsc-datepicker .mbsc-calendar-cell, .mbsc-ios.mbsc-datepicker .mbsc-calendar-cell-inner, .mbsc-ios.mbsc-datepicker .mbsc-calendar-grid {
        display: flex;
        justify-content: center;
    }

    .mbsc-icon > svg {
        display: none;
    }

    .mbsc-button-icon.mbsc-ltr {
        width: 36px;
        height: 36px;
        background-color: $color-primary-800;
        background-image: inline-svg('arrow-s-right', $color-white, transparent);
        background-position: center;
        background-size: 12px;
        background-repeat: no-repeat;
        border: 2px solid $color-primary-800;
        border-radius: 50%;
        transition: background-color .3s ease;
    }

    .mbsc-icon-button.mbsc-hover .mbsc-button-icon {
        background-color: $color-primary-600;
        border-color: $color-primary-1000;
    }

    .mbsc-calendar-header .mbsc-calendar-button.mbsc-icon-button {
        justify-content: flex-end;

        &.mbsc-calendar-button-prev {
            transform: rotate(180deg);
        }
    }

    .mbsc-calendar-header .mbsc-calendar-button.mbsc-icon-button:first-child {
        transform: rotate(180deg);
        justify-content: flex-end;
    }

    .mbsc-calendar-header .mbsc-ios.mbsc-button.mbsc-hover {
        opacity: 1;
    }

    .mbsc-calendar-labels {
        top: 0;
        left: initial;
        right: 0;
    }

    .mbsc-ios.mbsc-calendar-label {
        width: 16px;
        height: 16px;
        color: $color-white;
    }

    .mbsc-ios.mbsc-calendar-label-background {
        opacity: 1;
    }

    .mbsc-ios.mbsc-calendar-label-text {
        font-weight: 400;
        text-align: center;
        color: $color-secondary;
        color: #afafaf;
    }

    .mbsc-ios.mbsc-calendar-label-end.mbsc-ltr .mbsc-calendar-label-background, .mbsc-ios.mbsc-calendar-label-start.mbsc-rtl .mbsc-calendar-label-background {
        border-radius: 50%;
    }

    @include media-breakpoint-down(sm) {
        padding: 0 10px 24px !important;

        .mbsc-ios.mbsc-datepicker .mbsc-highlighted .mbsc-calendar-day-text, .mbsc-ios.mbsc-datepicker .mbsc-hover .mbsc-calendar-day-text, .mbsc-ios.mbsc-datepicker .mbsc-range-hover-end .mbsc-calendar-day-text, .mbsc-ios.mbsc-datepicker .mbsc-range-hover-start .mbsc-calendar-day-text, .mbsc-ios.mbsc-datepicker .mbsc-selected .mbsc-calendar-day-text, .mbsc-ios.mbsc-calendar-day-text {
            margin: initial;
        }
    }

    .mbsc-ios.mbsc-datepicker .mbsc-hover .mbsc-calendar-day-text {
        margin: pxToRem(3);
    }

    .mbsc-ios.mbsc-calendar-day-text {
        margin: pxToRem(3);
    }


}

.mbsc-ios.mbsc-hover .mbsc-calendar-cell-text {
    background-color: $color-primary-200;
}

.mbsc-ios.mbsc-selected .mbsc-calendar-cell-text {
    border-color: $color-primary-400;
    background: $color-primary-400;
    color: $color-black;
}

.mbsc-ios.mbsc-range-day::after {
    background-color: $color-primary-200;
}

.mbsc-ios.mbsc-calendar-week-nr, .mbsc-ios.mbsc-calendar-today, .mbsc-ios.mbsc-button-flat {
    color: $color-primary-800;
}

.mbsc-ios.mbsc-calendar-button.mbsc-button, .mbsc-ios.mbsc-range-control-value.active {
    color: $color-primary-800;
}