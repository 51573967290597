@import "@sx-core/src/scss/bootstrap";
@import "@sx-core/src/scss/backpack";

$foo: 'fo2';

/**
 * Backpack (CSS Reset)
 */

@import "layout/reset_backpack";
/**
 *  Responsive Font Size
 */
@import "~rfs/scss";

/**
 * Import icons
 */
@import "./icon/sass-inline-svg";

/**
 * Custom vars
 */
@import "./vars-figma-colors";
@import "./vars";

/**
 * Load Editor mixin
 */
@import "./layout/editor";

/**
 * Load mixins
 */
@import "./mixins";

/**
 * Import media dependend scss
 */

@import "@sx-core/src/scss/mixin/breakpoints";
@import "@sx-core/src/scss/mixin/media";

/**
 * Custom vars
 */
@import "./_vars-figma";
@import "./_vars-figma-colors";
@import "./_vars-figma-mixins";

/**
 * Layout styling
 */
//img.lazyload {
//    opacity: 0;
//    transition: opacity 1s 2s;
//}
//
//img.lazyloaded {
//    opacity: 1;
//    transition: opacity 1s 0.3s;
//}

@import "vendor/_hamburger.scss";
@import "layout/webfont";
@import "layout/default";
@import "layout/helper";

@import "element/image";
@import "element/video";
@import "element/intermediate";
@import "element/editor";
@import "element/teaser";
@import "element/filter";
@import "element/accordion";
@import "element/list";
@import "element/slider";
@import "element/header";
@import "element/footer";
@import "element/nav";

@import "element/keyvisual";
@import "element/breadcrumb";
@import "element/article";
@import "element/directions";
@import "element/downloads";
@import "element/cookiebot";
@import "element/systembar";
@import "element/maintenance";
@import "element/message";
@import "element/error";
@import "element/stoerer";
@import "element/element";
@import "element/button";
@import "element/gallery";
@import "element/_teaser-small.scss";
@import "element/_teaser-zb.scss";
@import "element/_asp.scss";
@import "element/_unit.scss";
@import "element/_units.scss";
@import "element/_accordion.scss";
@import "element/_calendar.scss";
@import "element/_infobox.scss";
@import "element/_anfrage-widget.scss";
@import "element/_unit-popup.scss";
@import "element/_accordion-teaser.scss";
@import "element/_toolbar.scss";

@import "formular/formular";
@import "formular/input";
@import "formular/textarea";
@import "formular/checkbox";
@import "formular/radio";
@import "formular/chosen";
@import "formular/datetimepicker";

/***/
//@import "layout/webfont";
//@import "layout/default";
//@import "layout/helper";
//@import "element/image_sx";
//@import "element/image";
//@import "element/video";
//@import "element/intermediate";
//@import "element/editor";
//@import "element/teaser";
//@import "element/filter_sx";
//@import "element/filter";
//@import "element/faq";
//@import "element/calendar";
//@import "element/list";
////@import "element/slider";
//@import "element/header";
//@import "element/footer";
//@import "element/nav";
//@import "element/keyvisual";
//@import "element/breadcrumb";
//@import "element/article";
//@import "element/directions";
//@import "element/downloads";
//@import "element/systembar";
//@import "element/maintenance";
//@import "element/message";
//@import "element/error";
//@import "element/stoerer";
//@import "element/search";
//@import "element/gallery";
//@import "element/asp";
//@import "element/teaser-small";
//@import "element/faq-contentmodule";
//@import "element/slider";
//@import "element/faq-teaser";
//@import "element/anfrage-widget";
//@import "element/toolbar";
//@import "element/chips";
//@import "element/units";
//@import "element/unit";
//@import "element/infobox";
//@import "formular/formular";
//@import "formular/button";
//@import "formular/input";
//@import "formular/textarea";
//@import "formular/checkbox";
//@import "formular/radio";
//@import "formular/chosen";
//@import "formular/datetimepicker";
//@import "component/element";
//@import "component/nouislider";
//@import "element/cancellation-info";
//@import "element/ratetable";
@import "element/feedback";
//@import "element/popup";
//@import "element/logo-banner";
//@import "element/countdown";
//@import "element/timeline";
//@import "element/popup-calendar";
//@import "element/zs-anfrage-widget";
//@import "element/zs-bookmark";
//@import "element/zs-chips";
//@import "element/zs-filter";
/***/

//@import "minimal/minimal";
//@import "element/iconfont";

//and now:
@import "element/fear";

