.faqteaser__outer {

}

.faqteaser {
  &-item {
    margin-bottom: pxToRem(48);
      &:hover {
          .faqteaser__item {
              box-shadow: 0px 4px 36px rgba(0,0,0,0.200);

          }
      }

  }

  &__item {
    position: relative;
    border-radius: $border-radius;
    @include getFigmaStyle('Shadow/MD');
      transition: all .5s ease;


    &::after {
      content: '';
      position: absolute;
      bottom: -48px;
      left: 50%;
      z-index: 10;
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 18px 18px 18px;
      border-color: transparent transparent $color-white transparent;
      transform: translateX(-50%);
      opacity: 0;
      transition: opacity .2s ease;

    }

    &.show-arrow {
      @include getFigmaStyle('Shadow/LG');
        box-shadow: none;
      &::after {
        opacity: 1;
      }

      .faqteaser__body::after {
        background-color: $color-primary-600;
        border-color: $color-primary-1000;
        //background-image: inline-svg('minus', $color-white, transparent);
        transform: rotate(-45deg) scale(.9);
      }
    }
  }

  &__img {
    box-shadow: 0 0 4px rgba($color-black, .1);
    border-radius: $border-radius;
    overflow: hidden;
    position: relative;

    .image {
      position: relative;

      &:before {
        content: "";
        display: block;
        background: rgba(0, 0, 0, 0.1);
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 1;
      }
    }
  }

  &__body {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
      z-index: 10;
    padding: pxToRem(24) pxToRem(52) pxToRem(24) pxToRem(24);
    color: $color-white;

    &::after {
      content: '';
      position: absolute;
      bottom: pxToRem(8);
      right: pxToRem(8);
      display: block;
      width: 36px;
      height: 36px;
      background-color: $color-primary-800;
      background-image: inline-svg('plus-m', $color-white, transparent);
      background-position: center;
      background-size: 24px;
      background-repeat: no-repeat;
      border: 2px solid $color-primary-800;
      border-radius: 50%;
      transition: all .3s ease;
    }
  }

  &-content__title {
    @include getFigmaStyle('Desktop/H4/Regular/Default');
  }
}
.faqteaser__item.show-arrow  {
    box-shadow: none;
}

.item-faq__storage, .item-faq__content {
  display: none;
}

.item-faq__content-outer {
  padding: pxToRem(36);
  background-color: $color-white;
  border-radius: $border-radius;

  margin-bottom: pxToRem(48);

}

.faqteaser-content__headline {
  margin-bottom: pxToRem(32);
  color: $color-primary-800;
  @include getFigmaStyle('Desktop/H5/Regular/Default');
}


@include media-breakpoint-down(lg) {
  .faqteaser {
    &-item {
      margin-bottom: pxToRem(20);
    }
  }
}


//------------ TEXTTEASER: --------------

.faqteaser-item--textfaq {
  .faqteaser__item {
    background: #fff;
  }

  .faqteaser__body {
    position: static;

    &:after {
      background-color: transparent !important;
      border: none !important;
      background-image: inline-svg('plus-m', $color-black, transparent);
      bottom: pxToRem(23);
      right: pxToRem(23);
    }

  }

  .faqteaser-content__title {
    @include getFigmaStyle('Desktop/LG/Regular/Default');
    color: #000;

  }
}

