.map2 .directions .directions__map {
    //height: calc(100vh - (72px + 48px + 40px));
    height: calc(100vh - 208px); //56px Header + 56px Toolbar + 48px Padding + 48px Padding
}

.map2 .map__rooms.uk-sticky-fixed {
    z-index: 979;
}

.filter2 {
    display: none;
}

.js-toggle-map {
    .map2 {
        display: none;
        opacity: 0;
        pointer-events: none;
        transition: opacity .7s ease;
    }

    .filter2 {
        display: block;
    }
}

.toolbar .chosen-container.chosen-container-active .chosen-single {
    border-color: transparent;
}

.toolbar .toolbar__sort .chosen-container .chosen-drop {
    //background: $color-white !important;
    //filter: drop-shadow(0 0 6px rgba(0, 0, 0, .08));
    //color: $font-color-white;

    color: $font-color;
    border-radius: 0.5rem;

    box-shadow: 0 11px 15px -7px rgba($color-black, 20%), 0 24px 38px 3px rgba($color-black, 14%), 0 9px 46px 8px rgba($color-black, 12%) !important;

    //&::before {
    //    position: absolute;
    //    content: '';
    //    left: 20px;
    //    top: -7px;
    //    display: inline-block;
    //    width: 0;
    //    height: 0;
    //    border-style: solid;
    //    border-width: 0 9px 8px 9px;
    //    border-color: transparent transparent $color-quaternary-dark transparent;
    //}
}

.toolbar .toolbar__sort .chosen-container .chosen-drop .chosen-results li:before {
    border: 1px solid $color-secondary !important;
    border-radius: 4px !important;
}

.toolbar .toolbar__sort .chosen-container .chosen-drop:before {
    top: -8px !important;
}

.toolbar .toolbar__sort .chosen-container .chosen-drop .chosen-results li.result-selected:before {
    //background: $color-primary !important;
    background-image: inline-svg('circle', $color-primary);
    background-position: center;
    background-size: 12px 12px;
    background-repeat: no-repeat;
}

.toolbar .toolbar__sort .chosen-container.chosen-container-active .chosen-single:after {
    background-image: inline-svg('arrow-down', $color-primary, transparent);
    border-color: $color-primary;
}

.toolbar .toolbar__sort .chosen-container .chosen-single:hover {
    border-color: transparent;
    cursor: pointer;
}

/* || Progress bar */

.uk-progress {
    height: 3px;
    margin-bottom: $spacing-xs;
    color: $color-primary;
    background-color: #E6DCD0;
}

progress[value] {
    -webkit-appearance: none;
    appearance: none;
}

progress[value]::-webkit-progress-value {
    background-color: $color-primary;
}

progress[value]::-moz-progress-bar {
    background-color: $color-primary;
}

progress[value] {
    color: $color-primary;
}

.progress__display {
    margin-bottom: 8px;
    font-size: pxToRem(17);
    font-weight: 700;
    color: $font-color;
    text-align: center;
}

/* || Filter */

.filter2 {
    .voffice-form {
        //color: $font-color-black;
        padding-bottom: 28px;
    }

    .voffice-form__header {
        padding: initial;
        margin-bottom: 24px;
        background: transparent;

        .voffice-form__title h2 {
            font-size: pxToRem(28);
            //color: $font-color-black;
        }

        .voffice-form__close {
            display: none;
        }
    }

    .voffice-form__body {
        min-height: auto;
        padding: initial;

        .voffice-form__gaeste, .voffice-form__datetime {
            flex-direction: column;
            align-items: stretch;
        }

        .voffice-form__label {
            font-size: pxToRem(17);
            font-weight: 600;
            margin-bottom: 12px;
            margin-right: initial;
        }

        .sx-person:hover {
            outline: none !important;
        }

        .sx-person__display {
            padding-left: 35px;

            &::before {
                content: none;
            }
        }

        .sx-daterange {
            //color: $font-color-black;

            &::placeholder {
                //color: $font-color-black;
            }
        }

        .voffice-form__main {
            margin-bottom: $spacing-m;
        }

        .voffice-form__main.third {
            background: transparent;
        }

        .voffice-form__checkbox {
            flex-wrap: wrap;
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(235px, calc(50% - 8px)));
            column-gap: 8px;
            margin-bottom: initial;
        }

        .voffice-form__checkbox .checkbox {
            width: auto;
        }

        .voffice-form__headline {
            font-size: pxToRem(24);
            margin-bottom: 24px;

            h3 {
                margin-bottom: initial;
            }
        }

        .voffice-form__price {
            .voffice-form__label {
                display: none;
            }

            input {
                padding-left: 24px;
                //color: $font-color-black;
            }
        }

        .chosen-container .chosen-single > span, .chosen-container.chosen-with-drop.chosen-container-active .chosen-single > span {
                //color: $font-color-black;
        }

        .chosen-container .chosen-drop .chosen-results .active-result {
            //color: $font-color-white;
            color: $font-color;
        }
    }

    .voffice-form__footer {
        position: relative;
        flex-direction: column;
        align-items: stretch;
        padding: $spacing-xs;
        background: transparent;
        border: 1px solid $color-secondary;
        border-radius: 16px 16px 40px 40px;

        .voffice-form__count {
            margin-bottom: 20px;
        }

        .voffice-form__button {
            display: flex;

            .button {
                flex: 1;
            }
        }
    }
}

/* || Bewertungen */

.mod_fewo-votings {
    margin-top: pxToRem(36);
}

.fewo_votings {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0 20px 80px;

    .voting_total {
        display: flex;
        align-items: center;
        margin-bottom: $spacing-xxs;

        &::before {
            content: '';
            position: relative;
            width: 24px;
            height: 24px;
            background-image: inline-svg('ranking', $color-secondary, transparent);
            background-position: center;
            background-size: 24px 24px;
            background-repeat: no-repeat;
        }
    }

    .vote_headline {
        margin-left: $spacing-s;
        margin-bottom: $spacing-xxs;
    }

    .fewo_votings_detail {
        margin-left: $spacing-s;
        margin-bottom: $spacing-xxs;
    }
}

/* || Media Queries */

@media(max-width: 1560px) {
    .filter2 {
        .voffice-form__main.first {
            flex-direction: column;
        }

        .voffice-form__body {
            .voffice-form__gaeste, .voffice-form__datetime {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
            }

            .voffice-form__label {
                margin-right: 24px;
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .filter2 {
        display: none !important;
    }

    .js-toggle-map {
        overflow: hidden;
    }

    .map2 .directions .directions__map {
        height: calc(100vh - 112px);
    }

    .sticky-target {
        display: none;
        transition: opacity .7s ease;
        opacity: 0;
        pointer-events: none;
        position: fixed;
        z-index: 918;
        padding-left: 0 !important;
        padding-right: 0 !important;
        left: 0;
        top: 112px;
        width: 100%;
    }

    //.map2 {
    //    display: none;
    //    transition: opacity .7s ease;
    //    opacity: 0;
    //    pointer-events: none;
    //    position: fixed;
    //    z-index: 918;
    //    padding-left: 0 !important;
    //    padding-right: 0 !important;
    //    left: 0;
    //    top: 112px;
    //    width: 100%;
    //}

    .js-toggle-map {
        .map2 {
            display: block;
            opacity: 1;
            pointer-events: auto;
            transition: opacity .7s ease;
        }

        .sticky-target {
            display: block;
            opacity: 1;
            pointer-events: auto;
            transition: opacity .7s ease;
        }
    }
}

@include media-breakpoint-down(sm) {
    .toolbar__sort {
        position: relative;
        width: auto;
        height: 32px;
        color: #fff !important;
        a {
            color: #fff !important;
        }
        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 32px;
            height: 32px;
            background-image: inline-svg('arrow-form-down', $color-primary-800, transparent);
            background-position: right center;
            background-size: 32px 32px;
            background-repeat: no-repeat;
        }
    }
    .toolbar__sort select {
        -webkit-appearance: none;
        padding-right: 40px;
        background: none;
        border: none;
        color: #fff !important;
        option {
            color: #fff !important;
        }
    }
    .fewo_votings_detail {
        margin-top: 10px;
        text-align: center;
    }


}

/* || Weitere Unterkünfte Teaser */

.units-teaser-more {
    display: none;
    background-color: $color-tertiary;
    margin-bottom: pxToRem(64);

    &__inner {
        position: relative;
        padding: pxToRem(36) pxToRem(24);
        background-color: $color-background;
        border-radius: 16px;
    }

    &__hl {
        font-size: pxToRem(30);
        font-weight: 700;
        //color: $font-color-white;

        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            margin: pxToRem(16) 0;
            background-color: $color-secondary;
        }
    }

    &__copy {
        margin-bottom: pxToRem(28);
        //color: $font-color-white;

        &::before {
            content: '';
            position: relative;
            top: 5px;
            display: inline-block;
            width: pxToRem(24);
            height: pxToRem(24);
            margin-right: pxToRem(12);
            background-image: inline-svg('check03', $color-secondary, transparent);
            background-position: center;
            background-size: 24px 24px;
            background-repeat: no-repeat;
        }

        a {
            //color: $font-color-white;
            text-decoration: underline;
        }
    }

    &__link {
        display: inline-flex;
        align-items: center;
        //color: $font-color-white;

        &::after {
            content: '';
            display: inline-block;
            width: pxToRem(32);
            height: pxToRem(32);
            margin-left: pxToRem(8);
            background-image: inline-svg('arrow-circle', $color-secondary, transparent);
            background-position: center;
            background-size: 32px 32px;
            background-repeat: no-repeat;
        }
    }

    &__stoerer {
        position: absolute;
        top: -48px;
        right: 0;
        width: 224px;

        -webkit-animation-duration: .8s;
        animation-duration: .8s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        -webkit-transform-origin: 50% 0;
        transform-origin: 50% 0;
        -webkit-animation-name: swing-bpl;
        animation-name: swing-bpl;
        -webkit-animation-direction: alternate;
        animation-direction: alternate;
        animation-iteration-count: infinite;
        -webkit-animation-iteration-count: infinite;
    }
}

.paginate-empty {
    margin-bottom: pxToRem(48);
}
.paginate-more {
    margin-top: pxToRem(36);
}

.js-paginate-done .units-teaser-more {
    display: block;
}

.units-teaser-more__stoerer--mobile {
    display: none;
    position: absolute;
}

@include media-breakpoint-down(md) {
    .units-teaser-more__inner {
        padding-top: pxToRem(200);
    }

    .units-teaser-more__stoerer {
        display: none;
    }

    .units-teaser-more__stoerer--mobile {
        display: flex;

        .units-teaser-more__stoerer {
            top: -240px;
            left: 0;
            right: 0;
            display: flex;
            width: 224px;
            margin: 0 auto;
        }
    }
}

@media(min-width: 1440px) {
    .units-teaser-more__hl h3, .units-teaser-more__copy {
        width: 75% !important;
    }
}

#units {
    scroll-margin-top: pxToRem(168);
}