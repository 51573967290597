@import "../icon/sass-inline-svg";

$datepicker-width: 282px;
$datepicker-button-height: 34px;

.sx-datepicker {
    cursor: default;
}

.bootstrap-datetimepicker-widget {
    z-index: 3001;
    position: absolute;
    display: block;
    margin: 5px;
    width: $datepicker-width;
    background: $color-white;
    border-radius: $border-radius;
    border: 1px solid $color-grey-100;
    box-shadow: 0 0 4px rgba($color-black, 0.1);
}

.bootstrap-datetimepicker-widget > ul {
    display: flex;
    flex-direction: column;
}

.bootstrap-datetimepicker-widget > ul > .picker-switch {
    order: 999;
}

.bootstrap-datetimepicker-widget .collapse {
    overflow: hidden;
    max-height: 768px;
    transition: max-height 0.4s;
}

.bootstrap-datetimepicker-widget .collapse:not(.show) {
    max-height: 0;
}

.bootstrap-datetimepicker-widget .icon-angle-up {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-size: 14px 14px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: inline-svg('angle-up', $color-primary);
}

.bootstrap-datetimepicker-widget .icon-angle-down {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-size: 14px 14px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: inline-svg('angle-down', $color-primary);
}

.bootstrap-datetimepicker-widget .icon-angle-left {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-size: 14px 14px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: inline-svg('angle-left', $color-primary);
}

.bootstrap-datetimepicker-widget .icon-angle-right {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-size: 14px 14px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: inline-svg('angle-right', $color-primary);
}

.bootstrap-datetimepicker-widget .icon-clock {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-size: 14px 14px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: inline-svg('clock', $color-primary);
}

.bootstrap-datetimepicker-widget .icon-calendar {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-size: 14px 14px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: inline-svg('calendar', $color-primary);
}

.bootstrap-datetimepicker-widget .icon-trash {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-size: 14px 14px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: inline-svg('trash', $color-primary);
}

.bootstrap-datetimepicker-widget ul {
    list-style-type: none;
}

.bootstrap-datetimepicker-widget table {
    width: 100%;
}

.bootstrap-datetimepicker-widget table thead th {
    vertical-align: middle;
}

.bootstrap-datetimepicker-widget table thead th [class*="icon-"] {
    width: calc(($datepicker-width - 10px) / 7) + 4.9;
    height: calc($datepicker-width / 7) * 1.1;
    display: block;
}

.bootstrap-datetimepicker-widget .datepicker-days .picker-switch,
.bootstrap-datetimepicker-widget .datepicker-months .picker-switch,
.bootstrap-datetimepicker-widget .datepicker-years .picker-switch,
.bootstrap-datetimepicker-widget .datepicker-decades .picker-switch {
    text-align: center;
    border-bottom: 1px solid $color-grey-100;
}

.bootstrap-datetimepicker-widget .datepicker-days .picker-switch,
.bootstrap-datetimepicker-widget .datepicker-months .picker-switch,
.bootstrap-datetimepicker-widget .datepicker-years .picker-switch {
    cursor: pointer;
    position: relative;
}

.bootstrap-datetimepicker-widget .datepicker-days .picker-switch:before,
.bootstrap-datetimepicker-widget .datepicker-months .picker-switch:before,
.bootstrap-datetimepicker-widget .datepicker-years .picker-switch:before {
    content: '\00a0';
    z-index: -1;
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 5px;
    right: 5px;
    display: block;
    border-radius: $border-radius;
    transition: background 0.1s;
}

.bootstrap-datetimepicker-widget .datepicker-days .picker-switch:hover:before,
.bootstrap-datetimepicker-widget .datepicker-months .picker-switch:hover:before,
.bootstrap-datetimepicker-widget .datepicker-years .picker-switch:hover:before {
    background: rgba($color-primary, 0.1);
}

.bootstrap-datetimepicker-widget .prev,
.bootstrap-datetimepicker-widget .next {
    cursor: pointer;
    position: relative;
    width: calc(($datepicker-width - 10px) / 7) + 4.9;
    padding: 0;
    text-align: center;
    border-bottom: 1px solid $color-grey-100;
}

.bootstrap-datetimepicker-widget .prev:before,
.bootstrap-datetimepicker-widget .next:before {
    content: '\00a0';
    z-index: -1;
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 5px;
    right: 5px;
    display: block;
    border-radius: $border-radius;
    transition: background 0.1s;
}

.bootstrap-datetimepicker-widget .prev:hover:before,
.bootstrap-datetimepicker-widget .next:hover:before {
    background: rgba($color-primary, 0.1);
}

.bootstrap-datetimepicker-widget .datepicker-days .dow,
.bootstrap-datetimepicker-widget .datepicker-days .day {
    overflow: hidden;
    width: calc(($datepicker-width - 10px) / 7) - 0.1;
    font-size: 14px;
    text-align: center;
}

.bootstrap-datetimepicker-widget .datepicker-days .dow:first-child,
.bootstrap-datetimepicker-widget .datepicker-days .day:first-child {
    padding-left: 5px;
}

.bootstrap-datetimepicker-widget .datepicker-days .day:first-child:before {
    left: 7px;
}

.bootstrap-datetimepicker-widget .datepicker-days .dow:last-child,
.bootstrap-datetimepicker-widget .datepicker-days .day:last-child {
    padding-right: 5px;
}

.bootstrap-datetimepicker-widget .datepicker-days .day:last-child:before {
    right: 7px;
}

.bootstrap-datetimepicker-widget .datepicker-days .dow {
    font-weight: bold;
    height: calc($datepicker-width / 7) * 1.1;
}

.bootstrap-datetimepicker-widget .datepicker-days .day {
    cursor: pointer;
    position: relative;
    height: calc($datepicker-width / 7) * 0.9;
}

.bootstrap-datetimepicker-widget .datepicker-days .day.old,
.bootstrap-datetimepicker-widget .datepicker-days .day.new {
    color: $color-grey-100;
}

.bootstrap-datetimepicker-widget .datepicker-days .day.today {
    color: $color-primary;
}

.bootstrap-datetimepicker-widget .datepicker-days .day.active {
    color: $color-white;
}

.bootstrap-datetimepicker-widget .datepicker-days .day:before {
    content: '\00a0';
    z-index: -1;
    position: absolute;
    top: 2px;
    bottom: 2px;
    left: 2px;
    right: 2px;
    display: block;
    border-radius: $border-radius;
    transition: background 0.1s;
}

.bootstrap-datetimepicker-widget .datepicker-days .day.active:before {
    background: $color-primary;
}

.bootstrap-datetimepicker-widget .datepicker-days .day:hover:before {
    background: rgba($color-primary, 0.1);
}

.bootstrap-datetimepicker-widget .datepicker-days .day.active:hover:before {
    background: $color-primary-600;
}

.bootstrap-datetimepicker-widget .datepicker-days .day.disabled:hover:before {
    background: transparent;
}

.bootstrap-datetimepicker-widget .datepicker-months .month,
.bootstrap-datetimepicker-widget .datepicker-years .year,
.bootstrap-datetimepicker-widget .datepicker-decades .decade,
.bootstrap-datetimepicker-widget .timepicker-hours .hour,
.bootstrap-datetimepicker-widget .timepicker-minutes .minute {
    cursor: pointer;
    float: left;
    margin: 5px;
    padding: 6px 4px;
    text-align: center;
    border-radius: $border-radius;
    transition: background 0.1s;
}

.bootstrap-datetimepicker-widget .datepicker-months .month,
.bootstrap-datetimepicker-widget .datepicker-years .year {
    width: calc(33.32% - 10px);
}

.bootstrap-datetimepicker-widget .datepicker-decades .decade {
    width: calc(50% - 10px);
}

.bootstrap-datetimepicker-widget .timepicker-hours .hour,
.bootstrap-datetimepicker-widget .timepicker-minutes .minute {
    width: calc(25% - 10px);
}

.bootstrap-datetimepicker-widget .datepicker-months .month.active,
.bootstrap-datetimepicker-widget .datepicker-years .year.active,
.bootstrap-datetimepicker-widget .datepicker-decades .decade.active,
.bootstrap-datetimepicker-widget .timepicker-hours .hour.active,
.bootstrap-datetimepicker-widget .timepicker-minutes .minute.active {
    color: $color-white;
    background: $color-primary;
}

.bootstrap-datetimepicker-widget .datepicker-months .month:hover,
.bootstrap-datetimepicker-widget .datepicker-years .year:hover,
.bootstrap-datetimepicker-widget .datepicker-decades .decade:hover,
.bootstrap-datetimepicker-widget .timepicker-hours .hour:hover,
.bootstrap-datetimepicker-widget .timepicker-minutes .minute:hover {
    background: rgba($color-primary, 0.1);
}

.bootstrap-datetimepicker-widget .datepicker-months .month.active:hover,
.bootstrap-datetimepicker-widget .datepicker-years .year.active:hover,
.bootstrap-datetimepicker-widget .datepicker-decades .decade.active:hover,
.bootstrap-datetimepicker-widget .timepicker-hours .hour.active:hover,
.bootstrap-datetimepicker-widget .timepicker-minutes .minute.active:hover {
    background: $color-primary-600;
}

.bootstrap-datetimepicker-widget .datepicker-days .day.disabled,
.bootstrap-datetimepicker-widget .datepicker-months .month.disabled,
.bootstrap-datetimepicker-widget .datepicker-years .year.disabled,
.bootstrap-datetimepicker-widget .datepicker-decades .decade.disabled,
.bootstrap-datetimepicker-widget .timepicker-hours .hour.disabled,
.bootstrap-datetimepicker-widget .timepicker-minutes .minute.disabled {
    cursor: not-allowed;
    color: $color-grey-300;
}

.bootstrap-datetimepicker-widget .datepicker-months .month.disabled:hover,
.bootstrap-datetimepicker-widget .datepicker-years .year.disabled:hover,
.bootstrap-datetimepicker-widget .datepicker-decades .decade.disabled:hover,
.bootstrap-datetimepicker-widget .timepicker-hours .hour.disabled:hover,
.bootstrap-datetimepicker-widget .timepicker-minutes .minute.disabled:hover {
    background: transparent;
}

.bootstrap-datetimepicker-widget .datepicker-months td:after,
.bootstrap-datetimepicker-widget .datepicker-years td:after {
    content: '\00a0';
    clear: both;
    display: block;
    height: 0;
}

.bootstrap-datetimepicker-widget .picker-switch td {
    padding: 5px 0 5px 5px;
}

.bootstrap-datetimepicker-widget .picker-switch td:last-child {
    padding: 5px;
}

.bootstrap-datetimepicker-widget .picker-switch a {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 $datepicker-button-height * 0.3;
    height: $datepicker-button-height;
    background: transparent;
    font-size: 15px;
    color: $color-black;
    border: 1px solid $color-grey-200;
    border-radius: $border-radius;
    transition: 0.15s background, 0.15s border, 0.15s color;
}

.bootstrap-datetimepicker-widget .picker-switch a:hover,
.bootstrap-datetimepicker-widget .picker-switch a:focus {
    color: $color-primary;
    background: rgba($color-primary, 0.1);
    border-color: $color-primary;
}

.bootstrap-datetimepicker-widget .timepicker {
    padding: 5px 0;
}

.bootstrap-datetimepicker-widget .timepicker-picker td {
    padding: 5px;
    text-align: center;
    vertical-align: middle;
}

.bootstrap-datetimepicker-widget .timepicker-picker td a,
.bootstrap-datetimepicker-widget .timepicker-picker td .timepicker-hour,
.bootstrap-datetimepicker-widget .timepicker-picker td .timepicker-minute,
.bootstrap-datetimepicker-widget .timepicker-picker td *:not(a) > span {
    cursor: pointer;
    display: block;
    padding: 4px 6px;
    border-radius: $border-radius;
    transition: background 0.1s;
}

.bootstrap-datetimepicker-widget .timepicker-picker td a:hover,
.bootstrap-datetimepicker-widget .timepicker-picker td .timepicker-hour:hover,
.bootstrap-datetimepicker-widget .timepicker-picker td .timepicker-minute:hover,
.bootstrap-datetimepicker-widget .timepicker-picker td *:not(a) > span:hover {
    background: rgba($color-primary, 0.1);
}

.bootstrap-datetimepicker-widget .timepicker-picker td a [class*="icon-"] {
    margin: 0 auto;
    width: 18px;
    display: block;
}
