@import "@sx-core/src/scss/element/article.scss";

.bg--coloured {
    padding-top: $modulspacing-desktop-m;
    //padding-bottom: $modulspacing-desktop-m;

    & > *:first-child {
        margin-top: initial;
    }

    & > *:last-child {
        padding-bottom: initial;
        margin-bottom: initial;
    }

    & > *:last-child:not(.teaser-zb) {
        padding-bottom: $modulspacing-desktop-m;
        position: relative;
        z-index: 10;
    }

    .headline.headline--title {
        //color: $color-white;
    }

    &.bg--lightblue {
        background-color: $color-primary-300;
    }

    &.bg--beige {
        background-color: $color-secondary-800;
    }
}

.teaser-zb + .bg--coloured {
    margin-top: -8rem;
}

.icon-bg--anker, .icon-bg--muschel, .icon-bg--seestern, .icon-bg--steuerrad, .icon-bg-left-anker, .icon-bg-left-muschel, .icon-bg-left-seestern, .icon-bg-left-steuerrad {
    position: relative;
    overflow-x: hidden;

    &::before, &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        display: block;
        background-repeat: no-repeat;
    }
}

.icon-bg-left-anker::before {
    top: 20%;
    background-image: url("/assets/img/graphics/anker.png");
    width: 424px;
    height: 476px;
    background-size: 424px 476px;
    transform: translateY(-50%) scale(.8);
}

.icon-bg-left-muschel::before {
    top: 20%;
    background-image: url("/assets/img/graphics/muschel.png");
    width: 175px;
    height: 156px;
    background-size: 174px 156px;
}

.icon-bg-left-seestern::before {
    top: 20%;
    background-image: url("/assets/img/graphics/seestern.png");
    width: 298px;
    height: 334px;
    background-size: 298px 334px;
}

.icon-bg-left-steuerrad::before {
    top: 20%;
    background-image: url("/assets/img/graphics/steuerrad.png");
    width: 323px;
    height: 288px;
    background-size: 323px 288px;
}

.icon-bg-right-anker::after {
    top: initial;
    bottom: 10%;
    left: initial;
    right: 0;
    z-index: 0;
    background-image: url("/assets/img/graphics/anker.png");
    width: 424px;
    height: 476px;
    background-size: 424px 476px;
}

.icon-bg-right-muschel::after {
    top: initial;
    bottom: 10%;
    left: initial;
    right: 0;
    z-index: -1;
    background-image: url("/assets/img/graphics/muschel.png");
    width: 175px;
    height: 156px;
    background-size: 174px 156px;
}

.icon-bg-right-seestern::after {
    top: initial;
    bottom: 10%;
    left: initial;
    right: 0;
    z-index: 0;
    background-image: url("/assets/img/graphics/seestern.png");
    width: 298px;
    height: 334px;
    background-size: 298px 334px;
}

.icon-bg-right-steuerrad::after {
    top: initial;
    bottom: 10%;
    left: initial;
    right: 0;
    z-index: 0;
    background-image: url("/assets/img/graphics/steuerrad.png");
    width: 323px;
    height: 288px;
    background-size: 323px 288px;
}

.slider + .bg-coloured, .list + .bg-coloured {
    padding-top: initial;
}

.article {
    margin-top: $modulspacing-desktop-m;
    margin-bottom: $modulspacing-desktop-m;

    &__image .image {
        border-radius: $border-radius;
        overflow: hidden;
    }

    &.article--nocontent {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.article__inner {
    position: relative;
    z-index: 10;
}

.headline {
    color: $color-primary-800;
    @include getFigmaStyle('Desktop/H1/Regular/Default');
    margin-bottom: pxToRem(32);
}

.headline.headline--topline, .headline.headline--title, .headline--topline {
    color: $color-primary-800;
    @include getFigmaStyle('Desktop/MD/Regular/Uppercase');
    margin-bottom: pxToRem(16);
}

.article-imgs-grid {
    margin-top: pxToRem(128);
    position: relative;

    .image {
        width: 60%;
        box-shadow: 0px 4px 36px 0px rgba(0, 0, 0, 0.20);
        margin-bottom: 20%;
        overflow: visible;

        &:nth-child(2) {
            position: absolute;
            margin-left: auto;
            bottom: 0%;
            right: 0;

            z-index: 10;

            .desc {
                display: block;
            }
        }

        .desc {
            position: absolute;
            bottom: -35px;
            text-align: center;
            width: 100%;
            display: none;
        }

        picture {
            border-radius: 16px;
            overflow: hidden;
        }

    }
}

@include media-breakpoint-down(md) {
    .article-imgs-grid {
        margin-top: pxToRem(64);
    }
    .article__image {
        margin-top: pxToRem(64);
    }
    .bg-coloured {
        padding: $modulspacing-mobile-m 0;
    }

    .slider + .bg-coloured, .list + .bg-coloured {
        padding-top: initial;
    }

    .article {
        margin-top: $modulspacing-mobile-m;
        margin-bottom: $modulspacing-mobile-m;

        &__inner {
            padding: 0 pxToRem(8);
        }
    }
    .article-intro .article {
        margin-top: pxToRem(72);
    }
}

/*** || CTA ***/

.cta {
    display: flex;
    flex-wrap: wrap;
    column-gap: pxToRem(28);
    row-gap: pxToRem(12);
    margin-top: pxToRem(48);
}

/*** || Preis ***/

//.price {
//    margin-top: pxToRem(36);
//    @include getFigmaStyle('Desktop/SM/Regular/Uppercase');
//
//    &__inner {
//        display: inline-flex;
//        align-items: center;
//        column-gap: pxToRem(16);
//    }
//
//    &__price {
//        @include getFigmaStyle('Desktop/H3/Bold/Default');
//    }
//}

/*** || Room ***/

.view-room .article {
    margin-bottom: $modulspacing-desktop-s;
}

/*** || Storno ***/

.article-storno {
    position: relative;
    padding: pxToRem(28);

    .image {
        border-radius: $border-radius;
        overflow: hidden;
    }

    &__inner {
        position: absolute;
        top: pxToRem(76);
        left: pxToRem(76);
        z-index: 10;
        padding: pxToRem(36);
        background-color: $color-secondary-800;
        border-radius: $border-radius;

        .headline {
            @include getFigmaStyle('Desktop/H4/Bold/Default');
        }
    }

    .storno__body {
        padding: pxToRem(36);
        background-color: $color-secondary-800;
    }

    .cta {
        justify-content: flex-start;
        margin-top: pxToRem(20);
    }
}

@include media-breakpoint-down(lg) {
    .article-storno {
        &__inner {
            top: pxToRem(44);
            left: pxToRem(44);
        }
    }
}

@include media-breakpoint-down(md) {
    .article-storno {
        padding: pxToRem(16);

        .image {
            border-radius: $border-radius $border-radius 0 0;
        }

        &__inner {
            position: relative;
            top: initial;
            left: initial;
            padding: pxToRem(28) pxToRem(16);
            border-radius: 0 0 $border-radius $border-radius;

            .headline {
                text-align: center;
                margin-bottom: pxToRem(20);
            }

            .content {
                text-align: center;
            }
        }

        .cta {
            justify-content: center;
        }
    }
    .icon-bg-left-steuerrad::before, .icon-bg-left-muschel::before, .icon-bg-left-seestern::before{
        top: 15%;
        transform: translateX(-40%) scale(.65);
    }
    .icon-bg-right-muschel::after, .icon-bg-right-steuerrad::after, .icon-bg-right-seestern::after{
        transform: translateX(50%) scale(.8);
        z-index: -1;
    }
    .icon-bg-left-anker::before {
        transform: translateX(-30%) scale(.4);
        top: 10%;
    }
    .icon-bg-right-anker::before{
        transform: translateX(-30%) scale(.4);
        z-index: -1;
    }
    .icon-bg-right-anker::after {
        transform: translateX(-30%) scale(.4);
        z-index: -1;
        top: 0;
    }
    .bg--coloured {
        padding-top: $modulspacing-mobile-m;
    }
    .bg--coloured {
        padding-top: $modulspacing-mobile-m;

        & > *:last-child:not(.teaser-zb) {
            padding-bottom: $modulspacing-mobile-m;
        }
    }
    .article-imgs-grid .image .desc {
        bottom: -48px;
        text-align: center;
        width: 100%;
        display: none;
        line-height: 1.3;
        font-size: 14px;
    }

}