.list__pagination {
    margin-top: 20px;
}

.paginate-more .button.button--primary:not(.button--link) {
    height: 32px;
    padding: 7px 24px;

    &:hover span, &:focus span {
        transform: translateX(-12px);
    }

    &::after {
        right: 4px;
    }
}
