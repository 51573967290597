/* || Filter PopUp */

.fewosfilter-popup {
    position: fixed;
    top: 150px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 992;
    width: 70%;
    max-width: 800px;
    height: calc(100vh - 200px);
    background-color: $color-primary-300;
    overflow: hidden;
    pointer-events: none;
    opacity: 0;
    transition: all .8s ease;

    .input.sx-daterange {
        min-width: 250px;
    }
}

.fewosfilter-bglayer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 990;
    display: block;
    width: 100%;
    background: rgba(0, 0, 0, .3);
    opacity: 0;
    pointer-events: none;
    transition: all .8s ease;
}

.fewo-sticky__input {
    padding: pxToRem(13) pxToRem(48);
    background-color: $color-white;
    background-size: 24px 24px;
    background-repeat: no-repeat;
    border-radius: 320px;
    @include getFigmaStyle('Shadow/MD');
    transition: box-shadow .3s ease;

    &--guests {
        background-image: inline-svg('user', $color-primary-800, transparent), inline-svg('angle-solid', $color-primary-800);
        background-position: left 12px center, right 12px center;
    }

    &--date {
        background-image: inline-svg('calendar', $color-primary-800, transparent), inline-svg('angle-solid', $color-primary-800);
        background-position: left 12px center, right 12px center;
    }
}

.voffice-form {
    //position: relative;
}

.voffice-form__header {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px 16px;
    background: $color-primary-400;

    .voffice-form__title h2 {
        color: $color-primary-800;
        @include getFigmaStyle('Desktop/H5/Regular/Default');
    }
}

.voffice-form__close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    margin-left: auto;
    background-color: $color-primary-800;
    background-image: inline-svg('plus-m', $color-white);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px;
    border: 2px solid $color-primary-800;
    border-radius: 50%;
    transform: rotate(45deg);
    cursor: pointer;
    transition: all .3s ease;

    @include media-breakpoint-up(lg) {
        &:hover, &:focus {
            background-color: $color-primary-600;
            border-color: $color-primary-1000;
        }
    }
}

.js-popup-filter-is-open .fewosfilter-popup, .js-popup-filter-is-open .fewosfilter-bglayer {
    pointer-events: auto;
    opacity: 1;
    border-radius: pxToRem(16);
}

.fewosfilter-popup__inner {
    height: 100%;
    position: relative;



    .voffice-form__body {
        height: calc(100vh - 360px);
        min-height: inherit;
        overflow: hidden;
        padding: 0 pxToRem(24);

        .voffice-form__body-inner {
            overflow-y: auto;
            overflow-x: hidden;
            padding: $spacing-s 12px 36px 0;
            height: 100%;
        }

        .voffice-form__checkbox {
            display: flex;
            flex-wrap: wrap;
            .checkbox {
                width: 33.332%;
            }
        }
    }
}

.fewosfilter-popup {
    .voffice-form__main.third {
        margin: 0;
    }
}

.voffice-form__main {
    &.third {
        padding: 12px 0;
        //background: rgba($color-black, .2);
        background: $color-primary-200;
        border-radius: 8px;
    }

    &:not(:last-child) {
        margin-bottom: 25px;
    }

    h3 {
        margin-bottom: 16px;
        font-size: pxToRem(22);
    }
}

.voffice-form {
    &__main {
        display: flex;
        //flex-direction: column;
        row-gap: pxToRem(12);
    }

    &__gaeste, &__datetime, &__select {
        display: flex;
        flex-direction: column;
        //justify-content: space-between;
        //align-items: center;

        .voffice-form__label {
            margin-bottom: pxToRem(12);
        }

        input, .sx-person, .sx-person__display {
            width: 100%;
        }
    }
}

.voffice-form__datetime .voffice-form__input {
    flex: 1;

    //.input.sx-daterange {
    //    width: 100%;
    //    background-position: center right 12px;
    //
    //    @include media-breakpoint-up(lg) {
    //        &:hover, &:focus {
    //            border-color: $color-secondary-800;
    //            box-shadow: 0 0 0 3px rgba($color-secondary-800, .2);
    //
    //            &::before {
    //                background-image: inline-svg('calendar-edit', $color-secondary-1000, transparent);
    //            }
    //
    //            //&::after {
    //            //    border-color: $color-primary;
    //            //    background-image: inline-svg('arrow-down', $color-secondary-1000, transparent);
    //            //}
    //        }
    //    }
    //}
}

.voffice-form__select {
    justify-content: space-between;
}

.voffice-form__label {
    margin-right: $spacing-xs;
}

//
//.fewosfilter-popup, #quote, .filter2 {
//    .chosen-pseudo, .chosen-container .chosen-single, .chosen-container .chosen-choices,
//    .chosen-container.chosen-with-drop.chosen-container-active .chosen-single, .chosen-container.chosen-with-drop.chosen-container-active .chosen-choices {
//        position: relative;
//        height: 46px;
//        min-width: 160px;
//        min-height: 46px;
//        padding-left: 16px;
//        padding-right: 48px;
//        color: $color-black;
//        background-image: none;
//        background-color: transparent;
//        border: 1px solid $color-secondary-800;
//        border-radius: 23px;
//        cursor: pointer;
//        transition: all .3s ease;
//
//        &:after {
//            content: '';
//            position: absolute;
//            right: 8px;
//            z-index: 10;
//            display: flex;
//            align-items: center;
//            width: 32px;
//            height: 32px;
//            background-image: inline-svg('arrow-down', $color-secondary-800, transparent);
//            background-position: center center;
//            background-size: 24px 24px;
//            background-repeat: no-repeat;
//            border: 1px solid $color-secondary-800;
//            border-radius: 50%;
//            transition: all .3s ease;
//        }
//
//        @include media-breakpoint-up(lg) {
//            &:hover, &:focus {
//                &::after {
//                    border-color: $color-secondary-1000;
//                    background-image: inline-svg('arrow-down', $color-secondary-1000, transparent);
//                }
//            }
//        }
//    }
//
//    .chosen-container.chosen-container-single.chosen-container-single-nosearch.chosen-container-active.chosen-with-drop {
//        .chosen-single {
//            box-shadow: 0 0 0 3px rgba($color-secondary-800, .2);
//
//            &::after {
//                border-color: $color-secondary-1000;
//                background-image: inline-svg('arrow-down', $color-secondary-1000, transparent);
//            }
//        }
//    }
//
//    .chosen-container .chosen-choices:hover, .chosen-container.chosen-container-active .chosen-choices, .chosen-container.chosen-container-active .chosen-single, .chosen-container .chosen-single:hover, .chosen-container.chosen-with-drop .chosen-choices, .chosen-container.chosen-with-drop .chosen-single, .chosen-pseudo:focus, .chosen-pseudo:hover {
//        border-color: $color-secondary-800;
//        box-shadow: 0 0 0 3px rgba($color-secondary-800, .2);
//    }
//
//    .chosen-container .chosen-single.chosen-default > span, .chosen-container.chosen-with-drop.chosen-container-active .chosen-single > span {
//        color: $color-black;
//    }
//}

//.chosen-container .chosen-drop {
//    //background: $color-primary-800 !important;
//    //background: $color-secondary !important;
//    top: calc(100% + 4px) !important;
//    background: $color-white !important;
//
//    border-radius: 0.5rem !important;
//
//    box-shadow: 0 11px 15px -7px rgba($color-black, 20%), 0 24px 38px 3px rgba($color-black, 14%), 0 9px 46px 8px rgba($color-black, 12%) !important;
//
//    color: $font-color;
//    //backdrop-filter: blur(9px);
//    border: none !important;
//}
//
//.chosen-container .chosen-drop .chosen-results {
//    border-top: none !important;
//}
//
//.chosen-container .chosen-drop .chosen-results li.active-result:hover {
//    background: rgba($color-primary, .15) !important;
//}
//
//.chosen-container .chosen-drop .chosen-results li.result-selected {
//    background: rgba($color-primary, .3) !important;
//}
//
//.chosen-container .chosen-drop .chosen-results li:first-child {
//    border-radius: 0.5rem 0.5rem 0 0;
//}
//
//.chosen-container .chosen-drop .chosen-results li:last-child {
//    border-radius: 0 0 0.5rem 0.5rem;
//}
//
//.fewosfilter-popup__inner, .filter2, .anfrage-widget {
//    .checkbox>label input[type=checkbox] {
//        transition: all .3s ease;
//    }
//
//    input[type=checkbox] {
//        width: 24px !important;
//        height: 24px !important;
//        transform: translateY(-4px);
//        background-color: transparent !important;
//        background-image: none !important;
//        border-radius: 4px !important;
//        border-color: $color-secondary-800 !important;
//        cursor: pointer;
//        transition: outline .3s ease;
//
//        @include media-breakpoint-up(lg) {
//            &:hover, &:focus {
//                //border-color: $color-secondary-1000 !important;
//                box-shadow: 0 0 0 3px rgba($color-secondary-800, .2);
//            }
//        }
//    }
//
//    .checkbox > label input[type=checkbox]:checked {
//        &::after {
//            content: '';
//            position: absolute;
//            top: 50%;
//            left: 50%;
//            transform: translate(-50%, -50%);
//            display: inline-block;
//            width: 12px;
//            height: 12px;
//            background-color: $color-primary;
//            border-radius: 50%;
//        }
//    }
//
//    .checkbox {
//        span {
//            display: inline-block;
//            padding-right: 10px;
//            font-size: pxToRem(15);
//        }
//    }
//}

//.voffice-form__price {
//    position: relative;
//    display: flex;
//    align-items: center;
//
//    .voffice-form__input {
//        width: 100%;
//
//        input {
//            width: 100%;
//            height: 46px;
//            color: $color-black;
//            background: transparent;
//            border: 1px solid $color-secondary-800;
//            border-radius: 23px;
//            transition: all .3s ease;
//        }
//    }
//}

//.noUi-horizontal {
//    height: 6px !important;
//    border: none !important;
//    background: rgba(#B6B6B6, .2) !important;
//    outline: 0 !important;
//    margin: 16px 6px 0 6px !important;
//
//    .noUi-handle {
//        top: -10px !important;
//        width: 24px !important;
//        height: 24px !important;
//        border-radius: 50% !important;
//        background-color: $color-white !important;
//        border: 1px solid $color-primary !important;
//        cursor: pointer;
//
//        &:before, &:after {
//            display: none;
//        }
//    }
//}
//
//.noUi-target {
//    box-shadow: none !important;
//}
//
//.noUi-connect {
//    background: $color-primary !important;
//}

.voffice-form__input {
    .price__min, .price__max {
        transition: all .3s ease;

        @include media-breakpoint-up(lg) {
            &:hover, &:focus {
                box-shadow: 0 0 0 3px rgba($color-secondary-800, .2);
            }
        }
    }
}

.voffice-form__footer {
    padding: pxToRem(24);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    width: 100%;
    background: $color-primary-400;

    .voffice-form__count {
        //flex: 1 1 auto;
        font-size: pxToRem(20);
    }

    .voffice-form__button {
        //margin-left: 12px;
        margin-left: auto;


        .button:not(.button--link) {
            margin-right: initial;
        }
    }
}

.fewosfilter-popup--reduced {
    height: auto;

    .voffice-form__body {
        height: auto;
    }

    .fewosfilter-popup__inner .voffice-form__body .voffice-form__body-inner {
        //padding-top: $spacing-m;
        //padding: pxToRem(24);
        padding-bottom: 8rem;
    }

    .voffice-form__label {
        min-width: 75px;
    }

    .voffice-form__gaeste {
        justify-content: initial;
    }
}

//
//.anfrage-widget__gaeste .sx-person__popover:before, .toolbar .sx-person__popover:before {
//    border-color: transparent transparent $color-primary-800 transparent;
//}
//
//.sx-person__popover {
//    //background: $color-primary-800;
//    //background: $color-secondary;
//    background: $color-white;
//    border-radius: 0.5rem;
//
//    box-shadow: 0 11px 15px -7px rgba($color-black, 20%), 0 24px 38px 3px rgba($color-black, 14%), 0 9px 46px 8px rgba($color-black, 12%) !important;
//
//    & > div {
//        //color: $color-black;
//        color: $font-color;
//    }
//
//    i {
//        background: transparent;
//        color: $color-secondary-800;
//        transition: all .3s ease;
//
//        @include media-breakpoint-up(lg) {
//            &:hover, &:focus {
//                color: $color-secondary-1000;
//                border-color: $color-secondary-1000;
//            }
//        }
//    }
//
//    &::before {
//        content: '';
//        position: absolute;
//        bottom: -10px;
//        left: 0;
//        display: block;
//        width: 100%;
//        height: 10px;
//    }
//
//    &:after {
//        content: '';
//        position: absolute;
//        top: -10px;
//        left: 0;
//        display: block;
//        width: 100%;
//        height: 10px;
//    }
//
//    span {
//        position: relative;
//        display: inline-flex;
//        justify-content: center;
//        width: 24px;
//        left: 21px;
//        //color: $color-black;
//        color: $font-color;
//    }
//
//    @include media-breakpoint-up(lg) {
//        i:hover, i:focus {
//            border-color: $color-secondary-1000;
//        }
//    }
//}

//.sx-person__display {
//    position: relative;
//
//    //&::before {
//    //    content: '';
//    //    position: absolute;
//    //    top: 50%;
//    //    transform: translateY(-50%);
//    //    z-index: 10;
//    //    display: inline-block;
//    //    width: 32px;
//    //    height: 32px;
//    //    background-position: center center;
//    //    background-size: 24px 24px;
//    //    background-repeat: no-repeat;
//    //    pointer-events: none;
//    //    left: 12px;
//    //    background-image: inline-svg('user', $color-secondary-800, transparent);
//    //    transition: all .3s ease;
//    //}
//}

//.anfrage-widget__gaeste .sx-person__display {
//    position: relative;
//    padding: 17px 56px;
//    background-image: inline-svg('arrow-form-down', $color-secondary-800, transparent);
//    background-position: center right 12px;
//    background-size: 32px 32px;
//    background-repeat: no-repeat;
//}
@include media-breakpoint-down(md) {
    .fewosfilter-popup {
        width: 90%;
    }
    .fewosfilter-popup__inner .voffice-form__body .voffice-form__checkbox .checkbox {
        width: 100%;
    }
    .voffice-form__footer {
        .voffice-form__count {
            //flex: 1 1 auto;
            font-size: pxToRem(17);
        }
    }
    .voffice-form__footer .voffice-form__button .button:not(.button--link) {
        font-size: pxToRem(15);
    }
}